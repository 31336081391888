<template>
  <div name="title">
    <h2>Introducing the Native Union Drop Wireless Charger</h2>
  </div>
  <div name="content">
    <p>
      Tired of dealing with tangled charging cables and cluttered countertops?
      Simplify your charging experience with the Native Union Drop Wireless
      Charger. This sleek and stylish charger offers fast and efficient wireless
      charging for your smartphone, keeping your device powered up and ready to
      go whenever you need it.
    </p>
    <h3>Are you tired of...</h3>
    <ul>
      <li>
        Untangling messy charging cables every time you need to charge your
        phone?
      </li>
      <li>Searching for an available power outlet to plug in your charger?</li>
      <li>
        Sacrificing style for functionality when it comes to charging
        accessories?
      </li>
    </ul>
    <p>
      Say goodbye to these frustrations with the Native Union Drop Wireless
      Charger. With its minimalist design and advanced charging technology, this
      charger provides a hassle-free charging solution for your smartphone.
    </p>
    <div class="images_and_colors">
      <div name="content_images">
        <div class="head_image_div">
          <img
            v-if="this.pictureChoice == 'cosmos'"
            class="head_image"
            src="../../../assets/native_union/drop_charger/drop_charger_cosmos.webp"
            alt="Drop Charger Cosmos"
          />
          <img
            v-else-if="this.pictureChoice == 'indigo'"
            class="head_image"
            src="../../../assets/native_union/drop_charger/drop_charger_indigo.webp"
            alt="Drop Charger Indigo"
          />
          <img
            v-else-if="this.pictureChoice == 'rose'"
            class="head_image"
            src="../../../assets/native_union/drop_charger/drop_charger_rose.webp"
            alt="Drop Charger Rose"
          />
          <img
            v-else-if="this.pictureChoice == 'sage'"
            class="head_image"
            src="../../../assets/native_union/drop_charger/drop_charger_sage.webp"
            alt="Drop Charger Sage"
          />
        </div>
      </div>
      <div name="available_colors" class="available_colors_parent">
        <h3 class="available_colors_text">Available colors:</h3>
        <div class="available_colors" name="A_lightning_colors">
          <img
            class="available_colors_image"
            src="../../../assets/native_union/drop_charger/drop_charger_cosmos.webp"
            alt="Belt Cable Cosmos"
            :class="{
              available_colors_image_active: pictureChoice == 'cosmos',
            }"
            @click="changePicture('cosmos')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/drop_charger/drop_charger_indigo.webp"
            alt="Belt Cable Indigo"
            :class="{
              available_colors_image_active: pictureChoice == 'indigo',
            }"
            @click="changePicture('indigo')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/drop_charger/drop_charger_rose.webp"
            alt="Belt Cable Rose"
            :class="{
              available_colors_image_active: pictureChoice == 'rose',
            }"
            @click="changePicture('rose')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/drop_charger/drop_charger_sage.webp"
            alt="Belt Cable Sage"
            :class="{
              available_colors_image_active: pictureChoice == 'sage',
            }"
            @click="changePicture('sage')"
          />
        </div>
      </div>
    </div>
    <p></p>
    <div name="features" class="product_features">
      <h3>Product features</h3>
      <ol>
        <li>
          <b>Fast Wireless Charging: </b>Equipped with
          <a class="link_to_internal_page" href="/types_of_products/wireless_chargers"
            >Qi-certified wireless charging technology</a
          >, the Drop Charger delivers fast and efficient charging for your
          smartphone. Say goodbye to slow charging times and hello to quick
          power-ups.
        </li>
        <li>
          <b>Sleek Design: </b>With its sleek and minimalist design, the Drop
          Charger complements any environment, whether it's your home or office.
          The soft-touch silicone surface prevents your phone from slipping
          while charging, providing added stability and peace of mind.
        </li>
        <li>
          <b>Universal Compatibility: </b>The Drop Charger is compatible with a
          wide range of Qi-enabled smartphones, including iPhone and Android
          devices. Whether you have the latest flagship phone or an older model,
          you can enjoy the convenience of wireless charging with the Drop
          Charger.
        </li>
        <li>
          <b>LED Indicator: </b>The built-in LED indicator light lets you know
          when your phone is charging, so you can easily check the status at a
          glance. The subtle glow adds a touch of sophistication to your
          charging setup without being too distracting.
        </li>
      </ol>
      <p>
        Upgrade your charging experience and declutter your space with the
        Native Union
        <a class="link_to_internal_page" :href="$store.state.link_to_drop_charger">Drop Wireless Charger</a>.
      </p>
    </div>
    <div name="personal_opinion" class="personal_opinion">
      <h3>Personal opinion</h3>
      <p>
        Whenever I was at work in the store or in the back-office, I would
        always put my phone on this wireless charger. The satisfaction of the
        phone charging wirelessly and the LED turning on only when it did
        properly charge are what really kickstarted my love for wireless
        charging. Now I can't think of a world with all the cables that would
        otherwise clutter my desk, nightstand and near the couch.
      </p>
    </div>

    <div name="CTA_div" class="CTA_div">
      <a class="button" :href="$store.state.link_to_drop_charger">Shop Now</a>
    </div>
  </div>
  <div name="similar_products" class="similar_products">
    <h3>Similar products</h3>
    <h4>
      <a class="link_to_internal_page" href="/brands/native_union/3_in_1_magnetic_charger"
        >3-in-1 Magnetic Charger</a
      >
    </h4>
    <p>
      If however, you plan on charging your phone, a pair of earbuds and an
      Apple Watch at the same time, I would suggest looking at the
      <a class="link_to_internal_page" href="/brands/native_union/3_in_1_magnetic_charger"
        >3-in-1 Magnetic Charger</a
      >
      from Native Union. This device also fast-charges all 3 the devices, making
      you ready to leave at a moments notice without fear of uncharged devices.
      Read more
      <a class="link_to_internal_page" href="/brands/native_union/3_in_1_magnetic_charger">here</a>.
    </p>
    <h4>
      <a class="link_to_internal_page" href="/brands/native_union/2_in_1_magnetic_charger"
        >2-in-1 Magnetic Charger</a
      >
    </h4>
    <p>
      When wanting to charge 2 devices at a time I would recommend the
      <a class="link_to_internal_page" href="/brands/native_union/2_in_1_magnetic_charger"
        >2-in-1 Magnetic Charger</a
      >
      from Native Union. This device can fast-charge your phone and a pair of
      earbuds, making sure both devices are always ready to use. Read more
      <a class="link_to_internal_page" href="/brands/native_union/2_in_1_magnetic_charger">here</a>.
    </p>
  </div>
</template>
  
  <script>
export default {
  data() {
    return { pictureChoice: "cosmos" };
  },
  methods: {
    changePicture(string) {
      this.pictureChoice = string;
    },
  },
};
</script>