<template>
    <h2>Functionalities Explained</h2>
    <p>Welcome to the 'Functionalities Explained' section of our website! Here, you will find detailed information about
        some of the key features and technologies associated with the products we advise. Whether you're curious about
        the benefits of fast charging, the significance of MFi certification, the functionality of Apple's Lightning
        connector, or the differences between USB-A and USB-C, we've got you covered. Simply click on the links below to
        learn more about each functionality and how it enhances your experience with the products on the site.</p>
    <ul>
        <li>Read more about Apple's <a class="link_to_internal_page"
                href="functionalities_explained/mfi_certification">MFi Certification</a></li>
        <li>Read more about Apple's <a class="link_to_internal_page"
                href="functionalities_explained/lightning_connector">Lightning Connector</a></li>
        <li>Read more about the differences between <a class="link_to_internal_page"
                href="functionalities_explained/usb_a_vs_usb_c">USB-A and USB-C</a></li>
        <li>Read more about <a class="link_to_internal_page" href="types_of_products/fast_chargers">Fast Charging</a>
        </li>
    </ul>
</template>