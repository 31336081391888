<template>
  <div name="title">
    <h2 v-if="this.cableChoice == 'USB_A_Lightning'">
      Introduction the Native Union: Belt Cable XL USB-A to Lightning
    </h2>
    <h2 v-else-if="this.cableChoice == 'USB_C_Lightning'">
      Introduction the Native Union: Belt Cable XL USB-C to Lightning
    </h2>
    <h2 v-else-if="this.cableChoice == 'USB_A_USB_C'">
      Introduction the Native Union: Belt Cable XL USB-A to USB-C
    </h2>
    <h2 v-else-if="this.cableChoice == 'USB_C_USB_C'">
      Introduction the Native Union: Belt Cable XL USB-C to USB-C
    </h2>
  </div>
  <div name="changeProductButtons" class="changeProductOnPageDiv">
    <button
      :class="{ active: cableChoice == 'USB_A_Lightning' }"
      class="changeProductOnPageButton"
      @click="changePage('USB_A_Lightning')"
    >
      USB-A to Lightning
    </button>
    <button
      :class="{ active: cableChoice == 'USB_A_USB_C' }"
      class="changeProductOnPageButton"
      @click="changePage('USB_A_USB_C')"
    >
      USB-C to USB-A
    </button>
    <button
      :class="{ active: cableChoice == 'USB_C_Lightning' }"
      class="changeProductOnPageButton"
      @click="changePage('USB_C_Lightning')"
    >
      USB-C to Lightning
    </button>
    <button
      :class="{ active: cableChoice == 'USB_C_USB_C' }"
      class="changeProductOnPageButton"
      @click="changePage('USB_C_USB_C')"
    >
      USB-C to USB-C
    </button>
  </div>
  <div name="content">
    <p>
      Fed up with short, unreliable charging cables that restrict your freedom
      of movement? Say hello to the Native Union Belt Cable XL, the ultimate
      solution for those seeking a durable, long-reaching charging cable
      designed to keep up with their on-the-go lifestyle.
    </p>
    <p>
      From having one color for each device to one color for each person in the
      household, Native Union has it all covered with their innovative and
      colorful charging cables. The Belt Cable XL with Lightning connector can
      charge any iPhone, iPad or AirPods with a Lightning Port because the
      cables are all
      <a class="link_to_internal_page" href="/functionalities_explained/mfi_certification"
        >MFi Certified</a
      >. The cables with a USB-C connection can be used with any USB-C device.
      The heavy duty cables are braded with different materials including an
      ultra strong aramid fibre core and kevlar and thus very strong and
      durable. The attached leather strap keeps the cable in it's place and
      untangled when traveling. Because of the 10-foot (3-meter) length the
      device can be used even when the device is on the charger. Together with a
      <a class="link_to_internal_page" href="/types_of_products/fast_chargers">fast charger</a>
      your device will charge quickly using this Native Union cable. Buy it
      <a
        v-if="this.cableChoice == 'USB_A_Lightning'"
        class="link_to_external_page"
        :href="$store.state.link_to_belt_cable_XL_A_Lightning"
        >here</a
      >
      <a
        v-else-if="this.cableChoice == 'USB_A_USB_C'"
        class="link_to_external_page"
        :href="$store.state.link_to_belt_cable_XL_A_C"
        >here</a
      >
      <a
        v-else-if="this.cableChoice == 'USB_C_Lightning'"
        class="link_to_external_page"
        :href="$store.state.link_to_belt_cable_XL_C_Lightning"
        >here</a
      >
      <a
        v-else-if="this.cableChoice == 'USB_C_USB_C'"
        class="link_to_external_page"
        :href="$store.state.link_to_belt_cable_XL_C_C"
        >here</a
      >.
    </p>
    <div class="images_and_colors">
      <div name="content_images">
        <div
          v-if="this.cableChoice == 'USB_A_Lightning'"
          class="head_image_div"
        >
          <img
            v-if="this.pictureChoice == 'zebra'"
            class="head_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_zebra_USB_A_Lightning.webp"
            alt="Belt Cable Zebra"
          />
          <img
            v-else-if="this.pictureChoice == 'indigo'"
            class="head_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_indigo_USB_A_Lightning.webp"
            alt="Belt Cable Indigo"
          />
          <img
            v-else-if="this.pictureChoice == 'rose'"
            class="head_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_rose_USB_A_Lightning.webp"
            alt="Belt Cable Rose"
          />
          <img
            v-else-if="this.pictureChoice == 'sage'"
            class="head_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_sage_USB_A_Lightning.webp"
            alt="Belt Cable Sage"
          />
          <img
            v-else-if="this.pictureChoice == 'cosmos'"
            class="head_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_cosmos_USB_A_Lightning.webp"
            alt="Belt Cable Cosmos"
          />
        </div>
        <div
          class="head_image_div"
          v-else-if="this.cableChoice == 'USB_C_Lightning'"
        >
          <img
            v-if="this.pictureChoice == 'zebra'"
            class="head_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_zebra_USB_C_Lightning.webp"
            alt="Belt Cable Cosmos"
          />
          <img
            v-else-if="this.pictureChoice == 'cosmos'"
            class="head_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_cosmos_USB_C_Lightning.webp"
            alt="Belt Cable Cosmos"
          />
          <img
            v-else-if="this.pictureChoice == 'kraft'"
            class="head_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_kraft_USB_C_Lightning.webp"
            alt="Belt Cable Cosmos"
          />
        </div>
        <div
          class="head_image_div"
          v-else-if="this.cableChoice == 'USB_A_USB_C'"
        >
          <img
            class="head_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_zebra_USB_A_USB_C.webp"
            alt="Belt Cable Cosmos"
          />
        </div>
        <div
          class="head_image_div"
          v-else-if="this.cableChoice == 'USB_C_USB_C'"
        >
          <img
            v-if="this.pictureChoice == 'zebra'"
            class="head_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_zebra_USB_C_USB_C.webp"
            alt="Belt Cable Cosmos"
          />
          <img
            v-else-if="this.pictureChoice == 'cosmos'"
            class="head_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_cosmos_USB_C_USB_C.webp"
            alt="Belt Cable Cosmos"
          />
          <img
            v-else-if="this.pictureChoice == 'kraft'"
            class="head_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_kraft_USB_C_USB_C.webp"
            alt="Belt Cable Cosmos"
          />
          <img
            v-else-if="this.pictureChoice == 'slate_green'"
            class="head_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_slate_green_USB_C_USB_C.webp"
            alt="Belt Cable Cosmos"
          />
        </div>
      </div>
      <div name="available_colors" class="available_colors_parent">
        <h3 class="available_colors_text">Available colors:</h3>
        <div
          class="available_colors"
          name="A_lightning_colors"
          v-if="this.cableChoice == 'USB_A_Lightning'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_zebra_USB_A_Lightning.webp"
            alt="Belt Cable Zebra"
            :class="{ available_colors_image_active: pictureChoice == 'zebra' }"
            @click="changePicture('zebra')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_indigo_USB_A_Lightning.webp"
            alt="Belt Cable Indigo"
            :class="{
              available_colors_image_active: pictureChoice == 'indigo',
            }"
            @click="changePicture('indigo')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_rose_USB_A_Lightning.webp"
            alt="Belt Cable Rose"
            :class="{ available_colors_image_active: pictureChoice == 'rose' }"
            @click="changePicture('rose')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_sage_USB_A_Lightning.webp"
            alt="Belt Cable Sage"
            :class="{ available_colors_image_active: pictureChoice == 'sage' }"
            @click="changePicture('sage')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_cosmos_USB_A_Lightning.webp"
            alt="Belt Cable Cosmos"
            :class="{
              available_colors_image_active: pictureChoice == 'cosmos',
            }"
            @click="changePicture('cosmos')"
          />
        </div>
        <div
          class="available_colors"
          name="A_C_colors"
          v-else-if="this.cableChoice == 'USB_A_USB_C'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_zebra_USB_A_USB_C.webp"
            alt="Belt Cable Zebra"
            :class="{ available_colors_image_active: pictureChoice == 'zebra' }"
            @click="changePicture('zebra')"
          />
        </div>
        <div
          class="available_colors"
          name="C_lightning_colors"
          v-else-if="this.cableChoice == 'USB_C_Lightning'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_zebra_USB_C_Lightning.webp"
            alt="Belt Cable Zebra"
            :class="{ available_colors_image_active: pictureChoice == 'zebra' }"
            @click="changePicture('zebra')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_cosmos_USB_C_Lightning.webp"
            alt="Belt Cable Cosmos"
            :class="{
              available_colors_image_active: pictureChoice == 'cosmos',
            }"
            @click="changePicture('cosmos')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_kraft_USB_C_Lightning.webp"
            alt="Belt Cable Kraft"
            :class="{ available_colors_image_active: pictureChoice == 'kraft' }"
            @click="changePicture('kraft')"
          />
        </div>
        <div
          class="available_colors"
          name="C_C_colors"
          v-else-if="this.cableChoice == 'USB_C_USB_C'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_zebra_USB_C_USB_C.webp"
            alt="Belt Cable Zebra"
            :class="{ available_colors_image_active: pictureChoice == 'zebra' }"
            @click="changePicture('zebra')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_cosmos_USB_C_USB_C.webp"
            alt="Belt Cable Cosmos"
            :class="{
              available_colors_image_active: pictureChoice == 'cosmos',
            }"
            @click="changePicture('cosmos')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_kraft_USB_C_USB_C.webp"
            alt="Belt Cable Kraft"
            :class="{ available_colors_image_active: pictureChoice == 'kraft' }"
            @click="changePicture('kraft')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_slate_green_USB_C_USB_C.webp"
            alt="Belt Cable Slate Green"
            :class="{
              available_colors_image_active: pictureChoice == 'slate_green',
            }"
            @click="changePicture('slate_green')"
          />
        </div>
      </div>
    </div>
    <p>
      Native Union is very fond of the amazing product they have created and can
      guarantee it's durability, this is why they have given their cables a
      lifetime limited warranty.
    </p>
    <div name="features" class="product_features">
      <h3>Product Features</h3>
      <ol>
        <li>
          <b>Extra-Long Length:</b> Enjoy the freedom to charge your devices
          from a distance with the extended 10-foot (3-meter) length of the Belt
          Cable XL. Whether you're lounging on the couch, working at your desk,
          or sitting in the backseat of a car, this cable provides ample reach
          without compromising on performance.
        </li>
        <li>
          <b>Reinforced Construction:</b> Built to last, the Belt Cable XL
          features a reinforced design with durable braided nylon and robust
          connectors that resist wear and tear. Say goodbye to frayed ends and
          unreliable connections.
        </li>
        <li>
          <b>Integrated Leather Strap:</b> Keep your cable neatly organized and
          tangle-free with the integrated leather strap. No more untangling
          knots or dealing with messy cables strewn across your workspace.
        </li>
        <li>
          <b>Universal Compatibility:</b> Equipped with a USB-A or USB-C
          connector on one end and a Lightning, USB-C, or Micro-USB connector on
          the other, the Belt Cable XL offers universal compatibility with a
          wide range of devices, including smartphones, tablets, and more.
        </li>
        <li>
          <b>Fast Charging Support:</b>
          With support for high-speed charging, the Belt Cable XL ensures quick
          and efficient power-ups for your devices, so you can spend less time
          waiting and more time doing what you love.
        </li>
      </ol>
      <p>
        Upgrade your charging experience and enjoy the convenience of a
        reliable, long-lasting cable with the Native Union Belt Cable XL.
      </p>
    </div>
    <div name="personal_opinion" class="personal_opinion">
      <h3>Personal opinion</h3>
      <p>
        Personally, I have two of these cables. One cable is in my car and
        connected to the audio system. That way, when I'm driving, I can charge
        my iPhone and play music at the same time. Thanks to the length of the
        cable, my iPhone can be passed around throughout the car and to the
        backseats so that passengers can charge their devices and put on some
        music as well.
      </p>
      <p>
        The other cable I have is at home at my couch. However I want to sit or
        lay on either couch, I always have the option to get the cable and
        connect it to my phone to start charging. No more having to sit close to
        the socket or hang over the side of the couch to charge, now it's just
        the way I like to hang on the couch.
      </p>
    </div>
  </div>
  <div name="CTA_div" class="CTA_div">
    <a
      v-if="this.cableChoice == 'USB_A_Lightning'"
      class="button"
        :href="$store.state.link_to_belt_cable_XL_A_Lightning"
      >Shop Now</a
    >
    <a
      v-else-if="this.cableChoice == 'USB_A_USB_C'"
      class="button"
        :href="$store.state.link_to_belt_cable_XL_C_C"
      >Shop Now</a
    >
    <a
      v-else-if="this.cableChoice == 'USB_C_Lightning'"
      class="button"
        :href="$store.state.link_to_belt_cable_XL_C_Lightning"
      >Shop Now</a
    >
    <a
      v-else-if="this.cableChoice == 'USB_C_USB_C'"
      class="button"
        :href="$store.state.link_to_belt_cable_XL_C_C"
      >Shop Now</a
    >
  </div>
  <div name="similar_products" class="similar_products">
    <h3>Similar Products</h3>
    <h4>
      <a href="/brands/native_union/night_cable" class="link_to_internal_page"
        >Night cable</a
      >
    </h4>
    <p>
      The Night Cable is the same cable as the Belt Cable XL (only available in
      USB-C to Lightning and USB-A to Lightning) but with a weighted knot. This
      knot ensures that the cable won't fall of off your nightstand and won't
      leave you searching in the dark for the charging cable. Read more about
      Native Union's
      <a href="/brands/native_union/night_cable" class="link_to_internal_page"
        >Night cable</a
      >.
    </p>
    <h4>
      <a href="/brands/native_union/desk_cable" class="link_to_internal_page"
        >Desk cable</a
      >
    </h4>
    <p>
      The Desk Cable has a premium designed weighted knot to keep the charger of
      your USB-C device on your desk, where you need it most. Whenever you take
      the charger out of your device, the weighted knot ensures the cable won't
      drop behind the desk. Read here more about Native Union's
      <a href="/brands/native_union/desk_cable" class="link_to_internal_page"
        >Desk cable</a
      >.
    </p>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      cableChoice: "USB_A_Lightning",
      pictureChoice: "zebra",
    };
  },
  methods: {
    changePage(string) {
      this.cableChoice = string;
      this.pictureChoice = "zebra";
    },
    changePicture(string) {
      this.pictureChoice = string;
    },
  },
};
</script>