<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- {{ isDark }}
  <button @click="toggleDark()">Toggle Dark Mode</button> -->
  <GadgetGrove />
</template>

<script>
import { useDark } from "@vueuse/core";
import GadgetGrove from "./components/GadgetGrove.vue";

export default {
  name: "App",
  components: {
    GadgetGrove,
  },
  data() {
    return {
      isDark: useDark(),
    };
  },
  methods: {
    toggleDark() {
      this.isDark = !this.isDark;
    },
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}

body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fafafc;
  background-color: #1c1c28;
  width: 70%;
  margin: 0 auto;
  padding: 20px;
}

ol {
  padding: 0 auto;
}

li {
  line-height: 175%;
}

h3 {
  margin: 0;
}

.native_union_belt_cable_colors {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.native_union_belt_cable_colors img {
  width: 100%;
  /* border: 1px solid red; */
}

.images_and_colors {
  display: flex;
  flex-direction: row;
  /* border: 1px solid red; */
}

.head_image_div {
  width: 100%;
  display: flex;
  justify-content: center;
  /* border: 1px solid orange; */
}

.head_image {
  width: 60%;
  object-fit: contain;
  /* border: 1px solid yellow; */
}

.available_colors_parent {
  width: 30%;
}

/* .available_colors_text{
  text-align: center;
} */

.available_colors {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.available_colors_image {
  width: 100%;
}

.available_colors_image:hover {
  cursor: pointer;
}

.available_colors_image_active {
  border: 1px solid orange;
}

.product_features {
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.product_feature {
  background-color: #1c1c28;
  width: 30%;
  height: 200px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CTA_div {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
}

.button {
  padding: 20px 40px;
  border-radius: 5px;
  cursor: pointer;
  color: #fafafc;
  background-color: #1c1c28;
  border: 1px solid #fafafc;
  text-decoration: none;
  transition: 0.25s;
}

.button:hover {
  color: #1c1c28;
  background-color: orange;
  transition: 0.25s;
}

.link_to_internal_page {
  color: #fafafc;
  text-decoration: underline;
  text-underline-offset: 2px;
  transition: 0.25s;
}

.link_to_internal_page:hover {
  text-decoration: underline orange;
  transition: 0.25s;
  cursor: pointer;
}

.link_to_external_page {
  color: #fafafc;
  text-decoration: underline;
  transition: 0.25s;
}

.link_to_external_page:hover {
  text-decoration: underline orange;
  transition: 0.25s;
  cursor: pointer;
}

.changeProductOnPageDiv {
  display: flex;
  flex-direction: row;
}

.changeProductOnPageButton {
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  color: #fafafc;
  background-color: #1c1c28;
  border: 1px solid #fafafc;
  text-decoration: none;
  transition: 0.25s;
}

.changeProductOnPageButton:hover {
  color: #1c1c28;
  background-color: orange;
  transition: 0.25s;
}

.active {
  color: #1c1c28;
  background-color: orange;
}

.brand_title {
  text-align: center;
}

.products_type {
  text-align: center;
  padding: 20px;
}

.product {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-bottom: 1px solid white;
}

.product_details {
  display: flex;
  flex-direction: row;
  /* border: 1px solid yellow; */
}

.product_page_image {
  width: 15%;
  padding: 10px 20px;
  /* border: 1px solid blue; */
}

.single_picture {
  text-align: center;
}

.single_image {
  width: 70%;
  height: auto;
}
</style>
