<template>
  <h2>
    Introducing the Native Union Night Cable: Redefining Convenience in Charging
  </h2>
  <div name="changeProductButtons" class="changeProductOnPageDiv">
    <button
      :class="{ active: cableChoice == 'USB_A_Lightning' }"
      class="changeProductOnPageButton"
      @click="changePage('USB_A_Lightning')"
    >
      USB-A to Lightning
    </button>

    <button
      :class="{ active: cableChoice == 'USB_C_Lightning' }"
      class="changeProductOnPageButton"
      @click="changePage('USB_C_Lightning')"
    >
      USB-C to Lightning
    </button>
  </div>
  <div name="content">
    <p>
      Are you tired of constantly struggling with short charging cables that
      keep you tethered to inconvenient locations? The Native Union Night Cable
      is the solution you've been searching for. Say goodbye to the frustration
      of cables that just won't reach where you need them to and embrace a new
      level of freedom and convenience in your charging routine.
    </p>
    <h3>Tired of Your Charging Cable Slipping Off Your Nightstand?</h3>
    <p>
      Traditional charging cables often end up on the floor, tangled and out of
      reach, especially when you need them most. The Night Cable features a
      uniquely designed weighted knot that anchors it securely to any flat
      surface. No more hunting around in the dark for your fallen cable or
      dealing with cables that constantly slip off your nightstand. With the
      Night Cable, your charging cable stays exactly where you want it, ensuring
      easy access whenever you need to power up your device.
    </p>
    <div class="images_and_colors">
      <div name="content_images">
        <div
          v-if="this.cableChoice == 'USB_A_Lightning'"
          class="head_image_div"
        >
          <img
            v-if="this.pictureChoice == 'zebra'"
            class="head_image"
            src="../../../assets/native_union/night_cable/night_cable_zebra_USB_A_Lightning.webp"
            alt="Belt Cable Zebra"
          />
          <img
            v-else-if="this.pictureChoice == 'cosmos'"
            class="head_image"
            src="../../../assets/native_union/night_cable/night_cable_cosmos_USB_A_Lightning.webp"
            alt="Belt Cable Cosmos"
          />
          <img
            v-else-if="this.pictureChoice == 'sage'"
            class="head_image"
            src="../../../assets/native_union/night_cable/night_cable_sage_USB_A_Lightning.webp"
            alt="Belt Cable Sage"
          />
          <img
            v-else-if="this.pictureChoice == 'indigo'"
            class="head_image"
            src="../../../assets/native_union/night_cable/night_cable_indigo_USB_A_Lightning.webp"
            alt="Belt Cable Indigo"
          />
          <img
            v-else-if="this.pictureChoice == 'rose'"
            class="head_image"
            src="../../../assets/native_union/night_cable/night_cable_rose_USB_A_Lightning.webp"
            alt="Belt Cable Rose"
          />
        </div>
        <div
          class="head_image_div"
          v-else-if="this.cableChoice == 'USB_C_Lightning'"
        >
          <img
            v-if="this.pictureChoice == 'zebra'"
            class="head_image"
            src="../../../assets/native_union/night_cable/night_cable_zebra_USB_C_Lightning.webp"
            alt="Belt Cable Cosmos"
          />
          <img
            v-else-if="this.pictureChoice == 'cosmos'"
            class="head_image"
            src="../../../assets/native_union/night_cable/night_cable_cosmos_USB_C_Lightning.webp"
            alt="Belt Cable Cosmos"
          />
          <img
            v-else-if="this.pictureChoice == 'kraft'"
            class="head_image"
            src="../../../assets/native_union/night_cable/night_cable_kraft_USB_C_Lightning.webp"
            alt="Belt Cable Kraft"
          />
        </div>
      </div>
      <div name="available_colors" class="available_colors_parent">
        <h3 class="available_colors_text">Available colors:</h3>
        <div
          class="available_colors"
          name="A_lightning_colors"
          v-if="this.cableChoice == 'USB_A_Lightning'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/native_union/night_cable/night_cable_zebra_USB_A_Lightning.webp"
            alt="Belt Cable Zebra"
            :class="{ available_colors_image_active: pictureChoice == 'zebra' }"
            @click="changePicture('zebra')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/night_cable/night_cable_cosmos_USB_A_Lightning.webp"
            alt="Belt Cable Cosmos"
            :class="{
              available_colors_image_active: pictureChoice == 'cosmos',
            }"
            @click="changePicture('cosmos')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/night_cable/night_cable_indigo_USB_A_Lightning.webp"
            alt="Belt Cable Indigo"
            :class="{
              available_colors_image_active: pictureChoice == 'indigo',
            }"
            @click="changePicture('indigo')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/night_cable/night_cable_rose_USB_A_Lightning.webp"
            alt="Belt Cable Rose"
            :class="{
              available_colors_image_active: pictureChoice == 'rose',
            }"
            @click="changePicture('rose')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/night_cable/night_cable_sage_USB_A_Lightning.webp"
            alt="Belt Cable Sage"
            :class="{
              available_colors_image_active: pictureChoice == 'sage',
            }"
            @click="changePicture('sage')"
          />
        </div>
        <div
          class="available_colors"
          name="A_C_colors"
          v-else-if="this.cableChoice == 'USB_C_Lightning'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/native_union/night_cable/night_cable_zebra_USB_C_Lightning.webp"
            alt="Belt Cable Zebra"
            :class="{ available_colors_image_active: pictureChoice == 'zebra' }"
            @click="changePicture('zebra')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/night_cable/night_cable_cosmos_USB_C_Lightning.webp"
            alt="Belt Cable Cosmos"
            :class="{
              available_colors_image_active: pictureChoice == 'cosmos',
            }"
            @click="changePicture('cosmos')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/night_cable/night_cable_kraft_USB_C_Lightning.webp"
            alt="Belt Cable Kraft"
            :class="{ available_colors_image_active: pictureChoice == 'kraft' }"
            @click="changePicture('kraft')"
          />
        </div>
      </div>
    </div>
    <p></p>
    <div name="features" class="product_features">
      <h3>Product Features:</h3>
      <ol>
        <li>
          <b>Weighted Knot:</b> Unlike standard cables that easily slip off
          surfaces, the Night Cable features a uniquely designed weighted knot
          that anchors it securely to any flat surface.
        </li>
        <li>
          <b>Reinforced Construction:</b> Built to withstand the demands of
          everyday use, the Night Cable boasts a durable braided nylon exterior
          that resists tangling and fraying.
        </li>
        <li>
          <b>Extended Reach:</b> With an impressive length of 10 feet (3-meter),
          the Night Cable provides you with the freedom to comfortably use your
          device while it charges.
        </li>
        <li>
          <b>Adjustable Length:</b> Customize the length of your cable to suit
          your needs with the included cable tidy.
        </li>
        <li>
          <b>Universal Compatibility:</b> Thanks to its versatile USB-A and
          USB-C to Lightning connector, the Night Cable is compatible with a
          wide range of devices.
        </li>
      </ol>
      <p>
        Upgrade your charging setup with the Native Union Night Cable and
        experience unparalleled convenience and durability.
      </p>
    </div>
    <div name="personal_opinion" class="personal_opinion">
      <h3>Personal opinion</h3>
      <p>
        This cable is my main charging cable since it lives on my nightstand and
        I mostly charge my phone at night. Thanks to the weighted knot (which
        can slide across the cable!), I can always find my cable, even when it's
        already dark in my room. Thanks to the length of the cable, I'm not
        tethered to the edge of the bed. That's why I think the Night Cable from
        Native Union is a cable everyone should have!
      </p>
    </div>

    <div name="CTA_div" class="CTA_div">
      <a
        v-if="this.cableChoice == 'USB_A_Lightning'"
        class="button"
        :href="$store.state.link_to_night_cable_A_Lightning"
        >Shop Now</a
      >
      <a
        v-else-if="this.cableChoice == 'USB_C_Lightning'"
        class="button"
        :href="$store.state.link_to_night_cable_C_Lightning"
        >Shop Now</a
      >
    </div>

    <div name="similar_products" class="similar_products">
      <h3>Similar Products</h3>
      <h4>
        <a href="/brands/native_union/belt_cable_XL" class="link_to_internal_page"
          >Belt cable XL</a
        >
      </h4>
      <p>
        The Belt Cable XL is the same cable as the Night Cable, but without the
        weighted knot. This knot ensures that the cable won't fall of off your
        nightstand. However, for a portable cable or when the cable can't fall
        off of anything, the knot doesn't really matter. Read more about Native
        Union's
        <a href="/brands/native_union/belt_cable_XL" class="link_to_internal_page"
          >Belt cable XL</a
        >.
      </p>
      <h4>
        <a href="/brands/native_union/desk_cable" class="link_to_internal_page"
          >Desk cable</a
        >
      </h4>
      <p>
        The Desk Cable has a premium designed weighted knot to keep the charger
        of your USB-C device on your desk, where you need it most. Whenever you
        take the charger out of your device, the weighted knot ensures the cable
        won't drop behind the desk. Read here more about Native Union's
        <a href="/brands/native_union/desk_cable" class="link_to_internal_page"
          >Desk cable</a
        >.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cableChoice: "USB_A_Lightning",
      pictureChoice: "zebra",
    };
  },
  methods: {
    changePage(string) {
      this.cableChoice = string;
      this.pictureChoice = "zebra";
    },
    changePicture(string) {
      this.pictureChoice = string;
    },
  },
};
</script>