<template>
  <div name="title">
    <h2>Find Your Lost Items Easily with Apple AirTag - Never Lose Anything Again!</h2>
  </div>
  <div name="content">
    <p>Introducing the revolutionary Apple AirTag, your ultimate solution for keeping track of your belongings. With its
      cutting-edge technology and seamless integration with your Apple devices, the AirTag helps you locate lost items
      quickly and effortlessly. Say goodbye to the stress of misplacing your keys, wallet, or bag with this innovative
      tracking device by your side.</p>
    <p>Never waste time searching for lost items again with the Apple AirTag. This compact and lightweight tracker
      attaches easily to your keys, backpack, or any item you want to keep tabs on. Simply open the Find My app on your
      iPhone or iPad to pinpoint the exact location of your lost item with precision. Say goodbye to the frustration of
      misplacing your essentials and hello to peace of mind with the AirTag by your side.</p>
    <div class="images_and_colors">
      <div name="content_images">
        <div class="head_image_div">
          <img v-if="this.pictureChoice == 'main'" class="head_image"
            src="../../../assets/apple/airtag/airtag_main.webp" alt="Belt Cable Cosmos" />
          <img v-else-if="this.pictureChoice == 'multiple'" class="head_image"
            src="../../../assets/apple/airtag/airtag_multiple.webp" alt="Belt Cable Cosmos" />
          <img v-else-if="this.pictureChoice == 'phone'" class="head_image"
            src="../../../assets/apple/airtag/airtag_phone.webp" alt="Belt Cable Cosmos" />
          <img v-else-if="this.pictureChoice == 'searching'" class="head_image"
            src="../../../assets/apple/airtag/airtag_searching.webp" alt="Belt Cable Cosmos" />
          <img v-else-if="this.pictureChoice == 'wallet'" class="head_image"
            src="../../../assets/apple/airtag/airtag_wallet.webp" alt="Belt Cable Cosmos" />
        </div>
      </div>
      <div name="available_colors" class="available_colors_parent">
        <h3 class="available_colors_text">Available pictures:</h3>
        <div class="available_colors" name="Airtag">
          <img class="available_colors_image" src="../../../assets/apple/airtag/airtag_main.webp" alt="Airtag"
            :class="{ available_colors_image_active: pictureChoice == 'main' }" @click="changePicture('main')" />
          <img class="available_colors_image" src="../../../assets/apple/airtag/airtag_multiple.webp" alt="Airtag"
            :class="{ available_colors_image_active: pictureChoice == 'multiple' }"
            @click="changePicture('multiple')" />
          <img class="available_colors_image" src="../../../assets/apple/airtag/airtag_wallet.webp" alt="Airtag"
            :class="{ available_colors_image_active: pictureChoice == 'wallet' }" @click="changePicture('wallet')" />
          <img class="available_colors_image" src="../../../assets/apple/airtag/airtag_phone.webp" alt="Airtag"
            :class="{ available_colors_image_active: pictureChoice == 'phone' }" @click="changePicture('phone')" />
          <img class="available_colors_image" src="../../../assets/apple/airtag/airtag_searching.webp" alt="Airtag"
            :class="{ available_colors_image_active: pictureChoice == 'searching' }"
            @click="changePicture('searching')" />
        </div>
      </div>
    </div>
    <p></p>
    <div name="features" class="product_features">
      <h3>Product features</h3>
      <ol>
        <li><b>Precision Tracking:</b> Utilize the ultra-wideband technology and the vast network of Apple devices to
          accurately locate your lost items within inches.</li>
        <li><b>Customizable Name and Emoji:</b> Personalize each AirTag with a custom name and emoji to easily identify
          and differentiate between multiple trackers.</li>
        <li><b>Lost Mode:</b> Activate Lost Mode to receive notifications when your AirTag is detected by nearby Apple
          devices, helping you retrieve your lost item quickly.</li>
        <li><b>Water and Dust Resistance:</b> Designed to withstand everyday wear and tear with IP67-rated water and
          dust resistance, ensuring durability and reliability.</li>
        <li><b>Long Battery Life:</b> Enjoy up to one year of battery life with the replaceable CR2032 battery,
          providing continuous tracking without the need for frequent charging.</li>
      </ol>
      <p>With the Apple AirTag, never worry about losing your keys, wallet, or other essentials again. This innovative
        tracking device offers precision location tracking, customizable features, and long-lasting durability, making
        it the perfect solution for anyone prone to misplacing items. Stay organized and stress-free with the AirTag by
        your side, and always know the whereabouts of your belongings with just a tap on your iPhone or iPad.</p>
    </div>
    <div name="personal_opinion" class="personal_opinion">
      <h3>Personal opinion</h3>
      <p>The AirTag is a very easy and small device but can have a huge impact on someone and their most important
        items. Since it can fit in almost all pockets and can be attached to keys (with an AirTag holder), you can keep
        track of anything. So are you someone that tends to forget where they placed their keys or their wallet? Or
        maybe you want to get a notification if you forgot your backpack if you leave somewhere? Then the AirTag is the
        perfect solution!</p>
    </div>

    <div name="CTA_div" class="CTA_div">
      <a class="button" :href="$store.state.link_to_airtag">Shop Now</a>
    </div>
  </div>
  <!-- <div name="similar_products" class="similar_products">
    <h3>Similar products</h3>
    <h4><a href=""></a></h4>
    <p><a href=""></a></p>
  </div> -->
</template>

<script>
export default {
  data() {
    return {pictureChoice: "main" };
  },
  methods: {
    changePicture(string) {
      this.pictureChoice = string;
    },
  },
};
</script>