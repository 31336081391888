<template>
  <div name="title">
    <h2 v-if="this.adapterChoice == '24W'">
      Belkin BoostCharge 24W Dual USB-A Wall Charger
    </h2>
    <h2 v-else-if="this.adapterChoice == '25W'">
      Belkin BoostCharge 25W USB-C Wall Charger
    </h2>
    <h2 v-else-if="this.adapterChoice == '37W'">
      Belkin BoostCharge 37W USB-C + USB-A Wall Charger
    </h2>
    <h2 v-else-if="this.adapterChoice == '67W'">
      Belkin BoostCharge 67W USB-C Wall Charger (3 Ports)
    </h2>
  </div>
  <div name="changeProductButtons" class="changeProductOnPageDiv">
    <button
      :class="{ active: adapterChoice == '24W' }"
      class="changeProductOnPageButton"
      @click="changePage('24W')"
    >
      2x USB-A
    </button>
    <button
      :class="{ active: adapterChoice == '25W' }"
      class="changeProductOnPageButton"
      @click="changePage('25W')"
    >
      1x USB-C
    </button>
    <button
      :class="{ active: adapterChoice == '37W' }"
      class="changeProductOnPageButton"
      @click="changePage('37W')"
    >
      1x USB-A & 1x USB-C
    </button>
    <button
      :class="{ active: adapterChoice == '67W' }"
      class="changeProductOnPageButton"
      @click="changePage('67W')"
    >
      3x USB-C
    </button>
  </div>
  <div name="content">
    <p v-if="this.adapterChoice == '24W'">
      Simplify your charging setup with the Belkin BoostCharge 24W Dual USB-A
      Wall Charger. This compact adapter features two USB-A ports, allowing you
      to charge two devices simultaneously without compromising on charging
      speed. Say goodbye to the clutter of multiple chargers and enjoy
      streamlined charging for your devices.
    </p>
    <p v-else-if="this.adapterChoice == '25W'">
      Elevate your charging experience with the Belkin BoostCharge 25W USB-C
      Wall Charger. This powerful adapter is designed to deliver fast and
      efficient charging for your USB-C devices, including smartphones, tablets,
      and laptops. Enjoy rapid charging without compromise.
    </p>
    <p v-else-if="this.adapterChoice == '37W'">
      Meet all your charging needs with the Belkin BoostCharge 37W USB-C + USB-A
      Wall Charger. This versatile adapter features a combination of USB-C and
      USB-A ports, allowing you to charge multiple devices simultaneously with
      optimal power delivery. Enjoy the convenience of charging different
      devices with one compact charger.
    </p>
    <p v-else-if="this.adapterChoice == '67W'">
      Experience unparalleled charging capabilities with the Belkin BoostCharge
      67W GaN USB-C Wall Charger. This advanced adapter features three USB-C
      ports, delivering high-speed charging performance for multiple devices
      simultaneously. Power up your devices efficiently with GaN technology.
    </p>

    <div class="images_and_colors">
      <div name="content_images">
        <div v-if="this.adapterChoice == '24W'" class="head_image_div">
          <img
            v-if="this.pictureChoice == 'main'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_24W_main.webp"
            alt="Belkin Boostcharge Adapter"
          />
          <img
            v-else-if="this.pictureChoice == 'back'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_24W_back.webp"
            alt="Belkin Boostcharge Adapter"
          />
          <img
            v-else-if="this.pictureChoice == 'side'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_24W_side.webp"
            alt="Belkin Boostcharge Adapter"
          />
        </div>
        <div v-else-if="this.adapterChoice == '25W'" class="head_image_div">
          <img
            v-if="this.pictureChoice == 'main'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_25W_main.webp"
            alt="Belkin Boostcharge Adapter"
          />
          <img
            v-else-if="this.pictureChoice == 'back'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_25W_back.webp"
            alt="Belkin Boostcharge Adapter"
          />
          <img
            v-else-if="this.pictureChoice == 'side'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_25W_side.webp"
            alt="Belkin Boostcharge Adapter"
          />
          <img
            v-else-if="this.pictureChoice == 'angle'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_25W_angle.webp"
            alt="Belkin Boostcharge Adapter"
          />
        </div>
        <div v-else-if="this.adapterChoice == '37W'" class="head_image_div">
          <img
            v-if="this.pictureChoice == 'main'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_37W_main.webp"
            alt="Belkin Boostcharge Adapter"
          />
          <img
            v-else-if="this.pictureChoice == 'back'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_37W_back.webp"
            alt="Belkin Boostcharge Adapter"
          />
          <img
            v-else-if="this.pictureChoice == 'side'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_37W_side.webp"
            alt="Belkin Boostcharge Adapter"
          />
          <img
            v-else-if="this.pictureChoice == 'angle'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_37W_angle.webp"
            alt="Belkin Boostcharge Adapter"
          />
        </div>
        <div v-else-if="this.adapterChoice == '67W'" class="head_image_div">
          <img
            v-if="this.pictureChoice == 'main'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_67W_main.webp"
            alt="Belkin Boostcharge Adapter"
          />
          <img
            v-else-if="this.pictureChoice == 'back'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_67W_back.webp"
            alt="Belkin Boostcharge Adapter"
          />
          <img
            v-else-if="this.pictureChoice == 'side'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_67W_side.webp"
            alt="Belkin Boostcharge Adapter"
          />
          <img
            v-else-if="this.pictureChoice == 'angle'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_67W_angle.webp"
            alt="Belkin Boostcharge Adapter"
          />
        </div>
      </div>
      <div name="available_colors" class="available_colors_parent">
        <h3 class="available_colors_text">Available colors:</h3>
        <div
          class="available_colors"
          name="A_lightning_colors"
          v-if="this.adapterChoice == '24W'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_24W_main.webp"
            alt="BoostCharge Adapter 24W"
            :class="{ available_colors_image_active: pictureChoice == 'main' }"
            @click="changePicture('main')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_24W_back.webp"
            alt="BoostCharge Adapter 24W"
            :class="{ available_colors_image_active: pictureChoice == 'back' }"
            @click="changePicture('back')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_24W_side.webp"
            alt="BoostCharge Adapter 24W"
            :class="{ available_colors_image_active: pictureChoice == 'side' }"
            @click="changePicture('side')"
          />
        </div>
        <div
          class="available_colors"
          name="A_C_colors"
          v-else-if="this.adapterChoice == '25W'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_25W_main.webp"
            alt="BoostCharge Adapter 24W"
            :class="{ available_colors_image_active: pictureChoice == 'main' }"
            @click="changePicture('main')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_25W_back.webp"
            alt="BoostCharge Adapter 24W"
            :class="{ available_colors_image_active: pictureChoice == 'back' }"
            @click="changePicture('back')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_25W_side.webp"
            alt="BoostCharge Adapter 24W"
            :class="{ available_colors_image_active: pictureChoice == 'side' }"
            @click="changePicture('side')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_25W_angle.webp"
            alt="BoostCharge Adapter 24W"
            :class="{ available_colors_image_active: pictureChoice == 'angle' }"
            @click="changePicture('angle')"
          />
        </div>

        <div
          class="available_colors"
          name="A_C_colors"
          v-else-if="this.adapterChoice == '37W'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_37W_main.webp"
            alt="BoostCharge Adapter 24W"
            :class="{ available_colors_image_active: pictureChoice == 'main' }"
            @click="changePicture('main')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_37W_back.webp"
            alt="BoostCharge Adapter 24W"
            :class="{ available_colors_image_active: pictureChoice == 'back' }"
            @click="changePicture('back')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_37W_side.webp"
            alt="BoostCharge Adapter 24W"
            :class="{ available_colors_image_active: pictureChoice == 'side' }"
            @click="changePicture('side')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_37W_angle.webp"
            alt="BoostCharge Adapter 24W"
            :class="{ available_colors_image_active: pictureChoice == 'angle' }"
            @click="changePicture('angle')"
          />
        </div>
        <div
          class="available_colors"
          name="A_C_colors"
          v-else-if="this.adapterChoice == '67W'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_67W_main.webp"
            alt="BoostCharge Adapter 24W"
            :class="{ available_colors_image_active: pictureChoice == 'main' }"
            @click="changePicture('main')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_67W_back.webp"
            alt="BoostCharge Adapter 24W"
            :class="{ available_colors_image_active: pictureChoice == 'back' }"
            @click="changePicture('back')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_67W_side.webp"
            alt="BoostCharge Adapter 24W"
            :class="{ available_colors_image_active: pictureChoice == 'side' }"
            @click="changePicture('side')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_67W_angle.webp"
            alt="BoostCharge Adapter 24W"
            :class="{ available_colors_image_active: pictureChoice == 'angle' }"
            @click="changePicture('angle')"
          />
        </div>
      </div>
    </div>
    <p v-if="this.adapterChoice == '24W'">
      The Belkin BoostCharge 24W Dual USB-A Wall Charger is perfect for
      households and individuals who need to charge multiple devices
      efficiently. With its 24W total output, each port delivers ample power to
      charge smartphones, tablets, wireless earbuds, and more. Its compact
      design and foldable prongs make it ideal for travel, ensuring you stay
      powered up wherever you go.
    </p>
    <p v-else-if="this.adapterChoice == '25W'">
      The BoostCharge 25W USB-C Wall Charger combines sleek design with high
      performance. Its USB-C Power Delivery (PD) technology ensures optimal
      charging speed for compatible devices, allowing you to recharge your
      devices quickly and efficiently. The compact form factor makes it perfect
      for home, office, or travel use.
    </p>
    <p v-else-if="this.adapterChoice == '37W'">
      The BoostCharge 37W USB-C + USB-A Wall Charger is a versatile solution for
      charging smartphones, tablets, laptops, and more. The USB-C port supports
      Power Delivery (PD) for fast charging, while the USB-A port provides
      standard charging for additional devices. Its compact design and dual-port
      functionality make it perfect for home, office, or travel use.
    </p>
    <p v-else-if="this.adapterChoice == '67W'">
      The BoostCharge 67W GaN USB-C Wall Charger is a powerhouse for charging
      laptops, tablets, smartphones, and more. Its Gallium Nitride (GaN)
      technology allows for a compact design without compromising on power
      output, making it an excellent choice for tech enthusiasts and
      professionals. Whether at home, in the office, or on the go, this charger
      offers unmatched performance and versatility.
    </p>
    <p>
      Each Belkin BoostCharge Adapter is designed to cater to specific charging
      needs while offering advanced features and reliable performance. Whether
      you're charging at home, in the office, or on the move, Belkin's
      BoostCharge Adapters provide a seamless charging experience with safety
      and efficiency in mind.
    </p>
    <div name="features" class="product_features">
      <h3>Product features</h3>
      <ol v-if="this.adapterChoice == '24W'">
        <li>Dual USB-A ports for simultaneous charging of two devices.</li>
        <li>Each port delivers up to 12W of power for fast charging.</li>
        <li>Compact and travel-friendly design with foldable prongs.</li>
        <li>Compatible with a wide range of USB-powered devices.</li>
        <li>
          Built-in protection against overcharging, overheating, and short
          circuits.
        </li>
      </ol>
      <ol v-else-if="this.adapterChoice == '25W'">
        <li>Single USB-C port with 25W power output.</li>
        <li>USB-C Power Delivery (PD) for fast charging.</li>
        <li>Compact and lightweight design for portability.</li>
        <li>Universal compatibility with USB-C devices.</li>
        <li>Advanced safety features for worry-free charging.</li>
      </ol>
      <ol v-else-if="this.adapterChoice == '37W'">
        <li>
          One USB-C port (25W) and one USB-A port (12W) for flexible charging
          options.
        </li>
        <li>Supports USB-C Power Delivery (PD) and standard USB charging.</li>
        <li>Compact and portable design for easy transport.</li>
        <li>Universal compatibility with various devices.</li>
        <li>Intelligent protection against overcharging and short circuits.</li>
      </ol>
      <ol v-else-if="this.adapterChoice == '67W'">
        <li>Three USB-C ports with up to 67W total output.</li>
        <li>GaN technology for compact size and efficient performance.</li>
        <li>USB-C Power Delivery (PD) support for rapid charging.</li>
        <li>Ideal for charging multiple devices simultaneously.</li>
        <li>
          Built-in safeguards to protect against overcharging and overheating.
        </li>
      </ol>
    </div>
    <div name="personal_opinion" class="personal_opinion">
      <h3>Personal opinion</h3>
      <p>Belkin is one of the brands I personally would recommend if we're talking about adapters. The thing about these chargers is that they're affordable, but also very reliable. All of these chargers have <a href="/types_of_products/fast_chargers" class="link_to_internal_page">fast-charging</a> compatibilities, meaning your device will be charged quickly without overheating. The fact that these chargers have multiple output options makes them very versatile and perfect for both at house or on the go.</p>
    </div>

    <div name="CTA_div" class="CTA_div">
      <a v-if="this.adapterChoice == '24W'" class="button" :href="$store.state.link_to_boostcharge_adapter_24W"
        >Shop Now</a
      >
      <a v-else-if="this.adapterChoice == '25W'" class="button" :href="$store.state.link_to_boostcharge_adapter_25W"
        >Shop Now</a
      >
      <a v-else-if="this.adapterChoice == '37W'" class="button" :href="$store.state.link_to_boostcharge_adapter_37W"
        >Shop Now</a
      >
      <a v-else-if="this.adapterChoice == '67W'" class="button" :href="$store.state.link_to_boostcharge_adapter_67W"
        >Shop Now</a
      >
    </div>
  </div>
  <div name="similar_products" class="similar_products">
    <h3>Similar products</h3>
    <h4><a class="link_to_internal_page" href="/brands/apple/iphone_and_ipad_chargers">Apple USB-C charger</a></h4>
    <p>A similar option for the Boostcharge Adapter would be <a class="link_to_internal_page" href="/brands/apple/iphone_and_ipad_chargers">Apple's USB-C charger</a>. This charger is Apple's option to charging your iPhone and iPad. However, it only has the option to charge via USB-C and can be a bit more pricy.</p>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      adapterChoice: "24W",
      pictureChoice: "main",
    };
  },
  methods: {
    changePage(string) {
      this.adapterChoice = string;
      this.pictureChoice = "main";
    },
    changePicture(string) {
      this.pictureChoice = string;
    },
  },
};
</script>