<template>
    <div class="pathbar">
        <p>
            <a class="link_to_internal_page" :href="this.homeLink">Home</a>
            <span v-if="this.pathWithLinks[1] != ''"> > <a class="link_to_internal_page"
                    :href="'/' + this.pathWithLinks[1]">{{ this.readablePath[1] }}
                </a></span>
            <span v-if="this.pathWithLinks[2] != undefined"> > <a class="link_to_internal_page"
                    :href="'/' + this.pathWithLinks[1] + '/' + this.pathWithLinks[2]">{{ this.readablePath[2]
                    }}</a></span>
            <span v-if="this.pathWithLinks[3] != undefined"> > <a class="link_to_internal_page"
                    :href="'/' + this.pathWithLinks[1] + '/' + this.pathWithLinks[2] + '/' + this.pathWithLinks[3]">{{
                        this.readablePath[3] }}</a></span>
        </p>
    </div>
</template>

<style scoped>
.pathbar {
    padding-top: 10px;
}

p {
    margin: 0;
    padding: 0;
}
</style>

<script>

export default {
    data() {
        return {
            homeLink: "/",
            pathWithLinks: [],
            readablePath: [],
        };
    },
    watch: {
        $route() {
            var route = this.$route.fullPath;
            var routeForUser = []
            var routeAfterPoundSign = route.split("#")[0]
            this.pathWithLinks = routeAfterPoundSign.split("/")
            this.pathWithLinks.forEach(function (element) {
                if (element.includes("_")) {
                    const splitWords = element.split("_");
                    var words = "";
                    splitWords.forEach(splitWord => {
                        const word = splitWord.charAt(0).toUpperCase() + splitWord.slice(1);
                        words = words + " " + word
                    });
                    const output = words.trim()
                    routeForUser.push(output)
                    // this.pathWithLinks[index] = output
                } else {
                    const output = element.charAt(0).toUpperCase() + element.slice(1);
                    routeForUser.push(output)
                }
            });
            this.readablePath = routeForUser
        }
    }
}
</script>