<template>
  <div name="title">
    <h2 v-if="this.cableChoice == 'USB_A_Lightning'">
      Introducing the Native Union: Belt Cable USB-A to Lightning
    </h2>
    <h2 v-else-if="this.cableChoice == 'USB_C_Lightning'">
      Introducing the Native Union: Belt Cable USB-C to Lightning
    </h2>
    <h2 v-else-if="this.cableChoice == 'USB_A_USB_C'">
      Introducing the Native Union: Belt Cable USB-A to USB-C
    </h2>
    <h2 v-else-if="this.cableChoice == 'USB_C_USB_C'">
      Introducing the Native Union: Belt Cable USB-C to USB-C
    </h2>
  </div>
  <div name="changeProductButtons" class="changeProductOnPageDiv">
    <button
      :class="{ active: cableChoice == 'USB_A_Lightning' }"
      class="changeProductOnPageButton"
      @click="changePage('USB_A_Lightning')"
    >
      USB-A to Lightning
    </button>
    <button
      :class="{ active: cableChoice == 'USB_A_USB_C' }"
      class="changeProductOnPageButton"
      @click="changePage('USB_A_USB_C')"
    >
      USB-A to USB-C
    </button>
    <button
      :class="{ active: cableChoice == 'USB_C_Lightning' }"
      class="changeProductOnPageButton"
      @click="changePage('USB_C_Lightning')"
    >
      USB-C to Lightning
    </button>
    <button
      :class="{ active: cableChoice == 'USB_C_USB_C' }"
      class="changeProductOnPageButton"
      @click="changePage('USB_C_USB_C')"
    >
      USB-C to USB-C
    </button>
  </div>
  <div name="content">
    <p>
      Are you tired of those cables that break when you've used them for a
      little while? All of the sudden they stop charging your device right when
      you need it the most. No time to go to the store to spend some more money
      and buy a new one. Native Union set out on a mission to rid the planet of
      these cables and built a set of premium charging cables that can last a
      lifetime!
    </p>
    <p>
      From having one color for each device to one color for each person in the
      household, Native Union has it all covered with their innovative and
      colorful charging cables. The Belt Cable with Lightning connector can
      charge any iPhone, iPad or AirPods with a Lightning Port because the
      cables are all
      <a class="link_to_internal_page" href="/functionalities_explained/mfi_certification"
        >MFi Certified</a
      >. The cables with a USB-C connection can be used with any USB-C device.
      The heavy duty cables are braded with different materials including an
      ultra strong aramid fibre core and kevlar and thus very strong and
      durable. The attached leather strap keeps the cable in it's place and
      untangled when traveling. Because of the 1.2m length the device can be
      used even when the device is on the charger. Together with a
      <a class="link_to_internal_page" href="/types_of_products/fast_chargers">fast charger</a>
      your device will charge quickly using this Native Union cable. Buy it
      <a
        v-if="this.cableChoice == 'USB_A_Lightning'"
        class="link_to_external_page"
        :href="$store.state.link_to_belt_cable_A_Lightning"
        >here</a
      >
      <a
        v-else-if="this.cableChoice == 'USB_A_USB_C'"
        class="link_to_external_page"
        :href="$store.state.link_to_belt_cable_A_C"
        >here</a
      >
      <a
        v-else-if="this.cableChoice == 'USB_C_Lightning'"
        class="link_to_external_page"
        :href="$store.state.link_to_belt_cable_C_Lightning"
        >here</a
      >
      <a
        v-else-if="this.cableChoice == 'USB_C_USB_C'"
        class="link_to_external_page"
        :href="$store.state.link_to_belt_cable_C_C"
        >here</a
      >.
    </p>
    <div class="images_and_colors">
      <div name="content_images">
        <div
          v-if="this.cableChoice == 'USB_A_Lightning'"
          class="head_image_div"
        >
          <img
            v-if="this.pictureChoice == 'zebra'"
            class="head_image"
            src="../../../assets/native_union/belt_cable/belt_cable_zebra_USB_A_Lightning.webp"
            alt="Belt Cable Zebra"
          />
          <img
            v-else-if="this.pictureChoice == 'indigo'"
            class="head_image"
            src="../../../assets/native_union/belt_cable/belt_cable_indigo_USB_A_Lightning.webp"
            alt="Belt Cable Indigo"
          />
          <img
            v-else-if="this.pictureChoice == 'rose'"
            class="head_image"
            src="../../../assets/native_union/belt_cable/belt_cable_rose_USB_A_Lightning.webp"
            alt="Belt Cable Rose"
          />
          <img
            v-else-if="this.pictureChoice == 'sage'"
            class="head_image"
            src="../../../assets/native_union/belt_cable/belt_cable_sage_USB_A_Lightning.webp"
            alt="Belt Cable Sage"
          />
          <img
            v-else-if="this.pictureChoice == 'cosmos'"
            class="head_image"
            src="../../../assets/native_union/belt_cable/belt_cable_cosmos_USB_A_Lightning.webp"
            alt="Belt Cable Cosmos"
          />
        </div>
        <div
          class="head_image_div"
          v-else-if="this.cableChoice == 'USB_C_Lightning'"
        >
          <img
            v-if="this.pictureChoice == 'zebra'"
            class="head_image"
            src="../../../assets/native_union/belt_cable/belt_cable_zebra_USB_C_Lightning.webp"
            alt="Belt Cable Cosmos"
          />
          <img
            v-else-if="this.pictureChoice == 'cosmos'"
            class="head_image"
            src="../../../assets/native_union/belt_cable/belt_cable_cosmos_USB_C_Lightning.webp"
            alt="Belt Cable Cosmos"
          />
          <img
            v-else-if="this.pictureChoice == 'kraft'"
            class="head_image"
            src="../../../assets/native_union/belt_cable/belt_cable_kraft_USB_C_Lightning.webp"
            alt="Belt Cable Cosmos"
          />
        </div>
        <div
          class="head_image_div"
          v-else-if="this.cableChoice == 'USB_A_USB_C'"
        >
          <img
            class="head_image"
            src="../../../assets/native_union/belt_cable/belt_cable_zebra_USB_A_USB_C.webp"
            alt="Belt Cable Cosmos"
          />
        </div>
        <div
          class="head_image_div"
          v-else-if="this.cableChoice == 'USB_C_USB_C'"
        >
          <img
            v-if="this.pictureChoice == 'zebra'"
            class="head_image"
            src="../../../assets/native_union/belt_cable/belt_cable_zebra_USB_C_USB_C.webp"
            alt="Belt Cable Cosmos"
          />
          <img
            v-else-if="this.pictureChoice == 'cosmos'"
            class="head_image"
            src="../../../assets/native_union/belt_cable/belt_cable_cosmos_USB_C_USB_C.webp"
            alt="Belt Cable Cosmos"
          />
          <img
            v-else-if="this.pictureChoice == 'kraft'"
            class="head_image"
            src="../../../assets/native_union/belt_cable/belt_cable_kraft_USB_C_USB_C.webp"
            alt="Belt Cable Cosmos"
          />
          <img
            v-else-if="this.pictureChoice == 'slate_green'"
            class="head_image"
            src="../../../assets/native_union/belt_cable/belt_cable_slate_green_USB_C_USB_C.webp"
            alt="Belt Cable Cosmos"
          />
        </div>
      </div>
      <div name="available_colors" class="available_colors_parent">
        <h3 class="available_colors_text">Available colors:</h3>
        <div
          class="available_colors"
          name="A_lightning_colors"
          v-if="this.cableChoice == 'USB_A_Lightning'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable/belt_cable_zebra_USB_A_Lightning.webp"
            alt="Belt Cable Zebra"
            :class="{ available_colors_image_active: pictureChoice == 'zebra' }"
            @click="changePicture('zebra')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable/belt_cable_indigo_USB_A_Lightning.webp"
            alt="Belt Cable Indigo"
            :class="{
              available_colors_image_active: pictureChoice == 'indigo',
            }"
            @click="changePicture('indigo')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable/belt_cable_rose_USB_A_Lightning.webp"
            alt="Belt Cable Rose"
            :class="{ available_colors_image_active: pictureChoice == 'rose' }"
            @click="changePicture('rose')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable/belt_cable_sage_USB_A_Lightning.webp"
            alt="Belt Cable Sage"
            :class="{ available_colors_image_active: pictureChoice == 'sage' }"
            @click="changePicture('sage')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable/belt_cable_cosmos_USB_A_Lightning.webp"
            alt="Belt Cable Cosmos"
            :class="{
              available_colors_image_active: pictureChoice == 'cosmos',
            }"
            @click="changePicture('cosmos')"
          />
        </div>
        <div
          class="available_colors"
          name="A_C_colors"
          v-else-if="this.cableChoice == 'USB_A_USB_C'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable/belt_cable_zebra_USB_A_USB_C.webp"
            alt="Belt Cable Zebra"
            :class="{ available_colors_image_active: pictureChoice == 'zebra' }"
            @click="changePicture('zebra')"
          />
        </div>
        <div
          class="available_colors"
          name="C_lightning_colors"
          v-else-if="this.cableChoice == 'USB_C_Lightning'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable/belt_cable_zebra_USB_C_Lightning.webp"
            alt="Belt Cable Zebra"
            :class="{ available_colors_image_active: pictureChoice == 'zebra' }"
            @click="changePicture('zebra')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable/belt_cable_cosmos_USB_C_Lightning.webp"
            alt="Belt Cable Cosmos"
            :class="{
              available_colors_image_active: pictureChoice == 'cosmos',
            }"
            @click="changePicture('cosmos')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable/belt_cable_kraft_USB_C_Lightning.webp"
            alt="Belt Cable Kraft"
            :class="{ available_colors_image_active: pictureChoice == 'kraft' }"
            @click="changePicture('kraft')"
          />
        </div>
        <div
          class="available_colors"
          name="C_C_colors"
          v-else-if="this.cableChoice == 'USB_C_USB_C'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable/belt_cable_zebra_USB_C_USB_C.webp"
            alt="Belt Cable Zebra"
            :class="{ available_colors_image_active: pictureChoice == 'zebra' }"
            @click="changePicture('zebra')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable/belt_cable_cosmos_USB_C_USB_C.webp"
            alt="Belt Cable Cosmos"
            :class="{
              available_colors_image_active: pictureChoice == 'cosmos',
            }"
            @click="changePicture('cosmos')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable/belt_cable_kraft_USB_C_USB_C.webp"
            alt="Belt Cable Kraft"
            :class="{ available_colors_image_active: pictureChoice == 'kraft' }"
            @click="changePicture('kraft')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/belt_cable/belt_cable_slate_green_USB_C_USB_C.webp"
            alt="Belt Cable Slate Green"
            :class="{
              available_colors_image_active: pictureChoice == 'slate_green',
            }"
            @click="changePicture('slate_green')"
          />
        </div>
      </div>
    </div>
    <p>
      Native Union is very fond of the amazing product they have created and can
      guarantee it's durability, this is why they have given their cables a
      lifetime limited warranty.
    </p>
    <p>
      The Belt Cable is the perfect cable to take with you on-the-go. When
      carefully rolled up and bound together with the genuine leather strap the
      cable can fit in practically any pocket, even in those pants with pockets
      too small for phones. Personally, I always have one of these cables (and
      <a class="link_to_internal_page" href="/types_of_products/fast_chargers">a fast charger</a>)
      in my backpack to be able to quickly charge my devices wherever I am.
    </p>
    <div name="features" class="product_features">
      <h3>Product Features</h3>
      <ol>
        <li>
          <b>Durable Braided Nylon:</b> The cable features a robust braided
          nylon exterior that resists fraying and stretching, providing enhanced
          durability for long-lasting use.
        </li>
        <li>
          <b>Integrated Leather Strap:</b> Keep your cable neatly organized and
          tangle-free with the integrated leather strap. Say goodbye to messy
          cables cluttering your workspace or bag.
        </li>
        <li>
          <b>4-Foot Length:</b> Enjoy flexibility and convenience with the
          optimized 4-foot (1.2-meter) length. Whether you're charging at home,
          in the office, or on the go, this cable offers ample reach without
          unnecessary tangles.
        </li>
        <li>
          <b>High-Speed Charging: </b>Equipped with a USB-A connector on one end
          and a Lightning connector on the other, the cable supports high-speed
          charging for your Apple devices. Say goodbye to slow charging times
          and hello to quick power-ups.
        </li>
        <li>
          <b
            ><a class="link_to_internal_page" href="/functionalities_explained/mfi_certification"
              >MFi Certified:</a
            ></b
          >
          Rest assured knowing that the Native Union Belt Cable is MFi certified
          by Apple, ensuring seamless compatibility and safe charging for your
          iOS devices. Charge with confidence knowing that your devices are
          protected.
        </li>
      </ol>
    </div>
    <div name="personal_opinion" class="personal_opinion">
      <h3>Personal opinion</h3>
      <p>
        Personally, since I started in sales I've always loved talking to people
        about the Native Union cables and always found it a pleasure to explain
        that there was a brand that listened to the problems of customers. Some
        customers even came back to tell me personally how happy they were with
        the purchase of their Native Union cable. To be completely, I've also
        seen broken Native Union cables. However, the company almost always
        swapped the cables for new ones, so that the customer could continue
        their premium charging experience. For me, the next time I need to buy
        new cables, they are for sure going to be Native Union cables.
      </p>
      <p>
        Wherever I go, I always have a Native Union USB-A to Lightning cable
        with me, together with a
        <a class="link_to_internal_page" href="/types_of_products/fast_chargers"
          >a fast charger</a
        >
        so I can charge anywhere. Since the cable is always neatly rolled up in
        my backpack, I never have to worry about the cable breaking or tangling
        up.
      </p>
    </div>
  </div>
  <div name="CTA_div" class="CTA_div">
    <a
      v-if="this.cableChoice == 'USB_A_Lightning'"
      class="button"
      :href="$store.state.link_to_belt_cable_A_Lightning"
      >Shop Now</a
    >
    <a
      v-else-if="this.cableChoice == 'USB_A_USB_C'"
      class="button"
      :href="$store.state.link_to_belt_cable_A_C"
      >Shop Now</a
    >
    <a
      v-else-if="this.cableChoice == 'USB_C_Lightning'"
      class="button"
      :href="$store.state.link_to_belt_cable_C_Lightning"
      >Shop Now</a
    >
    <a
      v-else-if="this.cableChoice == 'USB_C_USB_C'"
      class="button"
      :href="$store.state.link_to_belt_cable_C_C"
      >Shop Now</a
    >
  </div>
  <div name="similar_products" class="similar_products">
    <h3>Similar Products</h3>
    <h4>
      <a class="link_to_internal_page" href="/brands/belkin/boostcharge_cable"
        >BoostCharge cable</a
      >
    </h4>
    <p>
      The
      <a class="link_to_internal_page" href="/brands/belkin/boostcharge_cable"
        >BoostCharge cable</a
      >
      from Belkin is a more affordable option than the Native Union Belt Cable,
      it however doesn't have the lifelong warranty that Native Union offers
      with their products and isn't available in as many options as the Belt
      Cable. However, the BoostCharge cable is a more affordable option.
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cableChoice: "USB_A_Lightning",
      pictureChoice: "zebra",
    };
  },
  methods: {
    changePage(string) {
      this.cableChoice = string;
      this.pictureChoice = "zebra";
    },
    changePicture(string) {
      this.pictureChoice = string;
    },
  },
};
</script>