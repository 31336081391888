<template>
  <h2 class="brand_title">Apple</h2>
  <div class="brand_mission">
    <p>Apple's passion lies in creating groundbreaking products that enrich lives, blending innovation, design, and
      functionality. Their mission is to deliver the best user experience through premium hardware, software, and
      services. Apple strives to push the boundaries of technology while maintaining a commitment to sustainability and
      environmental responsibility. With a focus on quality and seamless integration, Apple aims to empower individuals
      and transform industries globally.
      For more detailed information, you can visit
      <a class="link_to_external_page" href="https://www.apple.com">Apple's official website</a>.
    </p>
  </div>
  <div class="products">
    <h3 id="accessories" class="products_type">Accessories</h3>
    <div class="product">
      <h4 class="product_title">
        <a href="/brands/apple/airpods_pro" class="link_to_internal_page">AirPods Pro</a>
      </h4>
      <div class="product_details">
        <img class="product_page_image" src="../../../assets/apple/airpods_pro/airpods_pro_main.webp" alt="AirPods Pro 2" />
        <p class="product_page_text">The AirPods Pro 2 feature advanced active noise cancellation, providing an
          immersive listening experience by blocking out external sounds. With improved audio quality, these earbuds
          deliver rich, high-fidelity sound with deep bass and crisp highs. They offer enhanced battery life, giving you
          up to 4.5 hours of listening time on a single charge and more with the charging case. The AirPods Pro 2 also
          include spatial audio with dynamic head tracking, creating a theater-like experience for music and video.
          Additionally, they come with customizable silicone tips for a comfortable and secure fit, making them perfect
          for all-day wear.
          <a class="link_to_internal_page" href="/brands/apple/airpods_pro">Read more here</a>.
        </p>
      </div>
    </div>
    <div class="product">
      <h4 class="product_title">
        <a href="/brands/apple/airtag" class="link_to_internal_page">AirTag</a>
      </h4>
      <div class="product_details">
        <img class="product_page_image" src="../../../assets/apple/airtag/airtag_main.webp" alt="AirTag" />
        <p class="product_page_text">AirTag is a compact and lightweight tracking device that helps you locate your
          belongings through the Find My app. It utilizes ultra-wideband technology for precise tracking, making it easy
          to find misplaced items like keys, wallets, or bags. The AirTag is designed with user privacy in mind,
          featuring end-to-end encryption and alerts for unwanted tracking. It's water and dust resistant, ensuring
          durability and reliability in various environments. With a user-replaceable battery that lasts over a year,
          AirTag is a convenient and dependable solution for keeping track of your valuables.
          <a class="link_to_internal_page" href="/brands/apple/airtag">Read more here</a>.
        </p>
      </div>
    </div>
  </div>
  <div class="products">
    <h3 id="chargers" class="products_type">Chargers</h3>
    <div class="product">
      <h4 class="product_title">
        <a href="/brands/apple/macbook_chargers" class="link_to_internal_page">MacBook Chargers</a>
      </h4>
      <div class="product_details">
        <img class="product_page_image" src="../../../assets/apple/chargers/30W_charger_front.webp" alt="" />
        <p class="product_page_text">MacBook Chargers are designed to provide efficient and reliable power to keep your
          laptop charged and ready for use. Available in various wattages, these chargers ensure compatibility with
          different MacBook models, delivering the optimal power required for each device. Featuring a compact and
          durable design, they are easy to carry and store, making them ideal for travel and everyday use. Many MacBook
          Chargers also come with detachable cables, allowing for flexible connectivity options and easier replacement
          if needed. Equipped with safety features like over-voltage and over-current protection, these chargers ensure
          safe and efficient charging.
          <a class="link_to_internal_page" href="/brands/apple/macbook_chargers">Read more here</a>.
        </p>
      </div>
    </div>
    <div class="product">
      <h4 class="product_title">
        <a href="/brands/apple/iphone_and_ipad_chargers" class="link_to_internal_page">iPad and iPhone chargers</a>
      </h4>
      <div class="product_details">
        <img class="product_page_image" src="../../../assets/apple/chargers/20W_charger_front.webp" alt="" />
        <p class="product_page_text">iPad and iPhone chargers are essential accessories for maintaining your devices'
          battery life and ensuring they are always ready for use. These chargers support fast charging capabilities,
          allowing you to quickly power up your devices and get back to your tasks. Available in various forms,
          including USB-C and Lightning connectors, they offer versatility and compatibility with different models.
          Designed with portability in mind, these chargers are compact and lightweight, making them convenient for
          travel and everyday use. With built-in safety features, iPad and iPhone chargers provide reliable and
          efficient charging without risking damage to your devices.
          <a class="link_to_internal_page" href="/brands/apple/iphone_and_ipad_chargers">Read more here</a>.
        </p>
      </div>
    </div>
  </div>
</template>