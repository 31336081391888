<template>
  <title>GGC - Native Union</title>
  <h2 class="brand_title">Native Union - A new normal</h2>
  <div class="brand_mission">
    <p>
      Native Union believes in creating a new normal. They believe in unlocking
      more flexibility and freedom in the way that we live our lives due to a
      positive force in tech. Not only is it their ambition to make beautiful
      tech accessories, they also provide considered solutions that elevate
      modern living, created with planet-friendly methods that make this way of
      life feasible for generations to come. Read more about Native Union's
      story
      <a
        class="link_to_external_page"
        href="https://www.nativeunion.com/pages/about-us"
        >here</a
      >
      or find all of my personally recommended Native Union products below.
    </p>
  </div>
  <div class="products">
    <h3 id="cables" class="products_type">Cables</h3>
    <div class="product">
      <h4 class="product_title">
        <a href="/brands/native_union/belt_cable" class="link_to_internal_page"
          >Belt Cable</a
        >
      </h4>
      <div class="product_details">
        <img
          class="product_page_image"
          src="../../../assets/native_union/belt_cable/belt_cable_zebra_USB_A_Lightning.webp"
          alt=""
        />
        <p class="product_page_text">
          The Belt Cable is a premium charging cable that you can take with you
          anywhere. It's neatly held together by a genuine leather strap that
          makes sure the cable won't tangle up. The cable is made of
          high-quality materials that makes sure this cable will not break. All
          in all, one of the best portable charging cables. Want to know more?
          Read more about Native Union's
          <a href="/brands/native_union/belt_cable" class="link_to_internal_page"
            >Belt Cable</a
          >.
        </p>
      </div>
    </div>
    <div class="product">
      <h4 class="product_title">
        <a href="/brands/native_union/belt_cable_XL" class="link_to_internal_page"
          >Belt Cable XL</a
        >
      </h4>
      <div class="product_details">
        <img
          class="product_page_image"
          src="../../../assets/native_union/belt_cable_XL/belt_cable_XL_zebra_USB_A_Lightning.webp"
          alt=""
        />
        <p class="product_page_text">
          The Belt Cable XL is a longer version of the premium Belt Cable. With
          a length of 10-foot (3-meter) the Belt Cable XL can reach far from the
          outlet and you can use it anywhere you want. Whether you're on the
          couch or in the backseat of the car, this cable can reach any place
          you want it to. With it's heavy duty materials it's breaded so you
          only need to buy one of these. Want to know more? Read more about
          Native Union's
          <a href="/brands/native_union/belt_cable_XL" class="link_to_internal_page"
            >Belt Cable XL</a
          >.
        </p>
      </div>
    </div>
    <div class="product">
      <h4 class="product_title">
        <a href="/brands/native_union/night_cable" class="link_to_internal_page"
          >Night Cable</a
        >
      </h4>
      <div class="product_details">
        <img
          class="product_page_image"
          src="../../../assets/native_union/night_cable/night_cable_zebra_USB_A_Lightning.webp"
          alt=""
        />
        <p class="product_page_text">
          The Night Cable from Native Union has a weighted knot that ties the
          cable to any flat surface. If you place one of these on your
          nightstand, it will solve the problem of leaving you fumbling around
          in the dark to find your cable. Since the cable is longer than the
          usual cable, it can be used anywhere you like! Want to know more? Read
          more about Native Union's
          <a href="/brands/native_union/night_cable" class="link_to_internal_page"
            >Night Cable</a
          >.
        </p>
      </div>
    </div>
    <div class="product">
      <h4 class="product_title">
        <a href="/brands/native_union/desk_cable" class="link_to_internal_page"
          >Desk Cable</a
        >
      </h4>
      <div class="product_details">
        <img
          class="product_page_image"
          src="../../../assets/native_union/desk_cable/desk_cable_sage_USB_C_USB_C.webp"
          alt=""
        />
        <p class="product_page_text">
          The Night Cable from Native Union is the solution to charging cables
          falling off of your desk when you unplug your device. With a neightly
          designed weighted anchor, it will stay on any flat surface you like.
          No more having to look for the charging cable of your device whenever
          you need it, since it will stay right where you left it. Want to know
          more? Read more about Native Union's
          <a href="/brands/native_union/desk_cable" class="link_to_internal_page"
            >Desk Cable</a
          >.
        </p>
      </div>
    </div>
  </div>
  <div class="products">
    <h3 id="wireless_chargers" class="products_type">Wireless chargers</h3>
    <div class="product">
      <h4 class="product_title">
        <a
          href="/brands/native_union/3_in_1_magnetic_charger"
          class="link_to_internal_page"
          >3-in-1 Magnetic Charger</a
        >
      </h4>
      <div class="product_details">
        <img
          class="product_page_image"
          src="../../../assets/native_union/magnetic_chargers/3_in_1_magnetic_charger.webp"
          alt=""
        />
        <p class="product_page_text">
          This Magnetic Charger is can charge an iPhone, an Apple Watch and
          earbuds all at the same time. No more looking around for different
          cables in different places to charge all your devices. Want to know
          more? Read more about Native Union's
          <a
            href="/brands/native_union/3_in_1_magnetic_charger"
            class="link_to_internal_page"
            >3-in-1 Magnetic Charger</a
          >.
        </p>
      </div>
    </div>
    <div class="product">
      <h4 class="product_title">
        <a
          href="/brands/native_union/2_in_1_magnetic_charger"
          class="link_to_internal_page"
          >2-in-1 Magnetic Charger</a
        >
      </h4>
      <div class="product_details">
        <img
          class="product_page_image"
          src="../../../assets/native_union/magnetic_chargers/2_in_1_magnetic_charger.webp"
          alt=""
        />
        <p class="product_page_text">
          When you have an iPhone and a pair of wirelessly chargable earbuds,
          this charger is a must. It can charge these devices at the same time,
          while still being a neat device to have on the desk or anywhere else.
          Curious? Read more about Native Union's
          <a
            href="/brands/native_union/2_in_1_magnetic_charger"
            class="link_to_internal_page"
            >2-in-1 Magnetic Charger</a
          >.
        </p>
      </div>
    </div>
    <div class="product">
      <h4 class="product_title">
        <a
          href="/brands/native_union/drop_charger"
          class="link_to_internal_page"
          >Drop Charger</a
        >
      </h4>
      <div class="product_details">
        <img
          class="product_page_image"
          src="../../../assets/native_union/drop_charger/drop_charger_cosmos.webp"
          alt=""
        />
        <p class="product_page_text">
          Buying your first wireless charger? Or simply just need to wirelessly charge only one device at a time? This device can do just that! Whether you want to charge your phone or a pair of earbuds, the Drop Charger can do just that! Read here more about Native Union's
          <a
            href="/brands/native_union/drop_charger"
            class="link_to_internal_page"
            >Drop Charger</a
          >.
        </p>
      </div>
    </div>
  </div>
</template>