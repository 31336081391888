<template>
  <div name="title">
    <h2>Introducing the Native Union Snap 2-in-1 Magnetic Wireless Charger</h2>
  </div>
  <div name="content">
    <p>
      Looking for a versatile charging solution that keeps your devices powered
      up and organized? Meet the Native Union Snap 2-in-1 Magnetic Wireless
      Charger. Designed to simplify your charging routine, this charger offers
      seamless compatibility with your smartphone and earbuds, ensuring
      convenient charging wherever you are.
    </p>
    <h3>Are you tired of...</h3>
    <ul>
      <li>Dealing with multiple charging cables cluttering your space?</li>
      <li>
        Struggling to find a wireless charger that works with both your
        smartphone and earbuds?
      </li>
      <li>
        Sacrificing style for functionality when it comes to charging
        accessories?
      </li>
    </ul>
    <p>
      Say goodbye to these frustrations with the Native Union Snap 2-in-1
      Magnetic Wireless Charger. With its sleek design and advanced technology,
      this charger provides a hassle-free charging experience for your devices.
    </p>
    <div class="images_and_colors">
      <div name="content_images">
        <div class="head_image_div">
          <img v-if="this.pictureChoice == 'head'" class="head_image"
            src="../../../assets/native_union/magnetic_chargers/2_in_1_magnetic_charger.webp" alt="Magnetic Charger" />
          <img v-else-if="this.pictureChoice == 'charging'" class="head_image"
            src="../../../assets/native_union/magnetic_chargers/2_in_1_magnetic_charger_charging.webp"
            alt="Magnetic Charger" />
        </div>
      </div>
      <div name="available_colors" class="available_colors_parent">
        <h3 class="available_colors_text">Available colors:</h3>
        <div class="available_colors" name="A_lightning_colors">
          <img class="available_colors_image"
            src="../../../assets/native_union/magnetic_chargers/2_in_1_magnetic_charger.webp" alt="Magnetic Charger"
            :class="{ available_colors_image_active: pictureChoice == 'head' }" @click="changePicture('head')" />
          <img class="available_colors_image"
            src="../../../assets/native_union/magnetic_chargers/2_in_1_magnetic_charger_charging.webp"
            alt="Magnetic Charger" :class="{
              available_colors_image_active: pictureChoice == 'charging',
            }" @click="changePicture('charging')" />
        </div>
      </div>
    </div>
    <p></p>
    <div name="features" class="product_features">
      <h3>Product features</h3>
      <ol>
        <li>
          <b>2-in-1 Charging: </b>Charge your smartphone and smartwatch
          simultaneously with the Snap 2-in-1 Magnetic Wireless Charger. Whether
          you're at home, in the office, or traveling, this charger offers a
          convenient solution for all your charging needs.
        </li>
        <li>
          <b>Magnetic Alignment: </b>The built-in magnets ensure perfect
          alignment for your devices, allowing for efficient charging without
          the hassle of misaligned connections. Say goodbye to fumbling with
          cables and struggling to find the charging port.
        </li>
        <li>
          <b>Fast Wireless Charging: </b>Equipped with Qi wireless charging
          technology, the Snap Charger delivers fast and reliable charging for
          your devices. Say goodbye to slow charging times and hello to quick
          power-ups.
        </li>
        <li>
          <b>Sleek Design: </b>With its sleek and compact design, the Snap
          Charger complements any environment, whether it's your home or office.
          Say goodbye to bulky chargers that clutter your space.
        </li>
      </ol>
      <p>
        Upgrade your charging setup and simplify your life with the Native Union
        <a class="link_to_external_page" :href="$store.state.link_to_2_in_1_magnetic_charger">Snap 2-in-1 Magnetic
          Wireless Charger</a>.
      </p>
    </div>
    <div name="personal_opinion" class="personal_opinion">
      <h3>Personal opinion</h3>
      <p>
        This is one of the only products from Native Union that I don't
        personally have. The only reason for that is that I use an Apple Watch
        and thus I prefer the
        <a class="link_to_internal_page" href="/brands/native_union/3_in_1_magnetic_charger">3-in-1 Magnetic
          Charger</a>. However, if I wouldn't have an Apple Watch, I would most definitely
        buy this charger as it allows me to charge my iPhone and my earbuds (<a class="link_to_internal_page"
          href="/brands/apple/airpods_pro">AirPods Pro</a>) at the same time, without having to look for the proper
        cables. Thus
        this (and the 3-in-1-version) are both my personal favorites!
      </p>
    </div>

    <div name="CTA_div" class="CTA_div">
      <a class="button" :href="$store.state.link_to_2_in_1_magnetic_charger">Shop Now</a>
    </div>
  </div>
  <div name="similar_products" class="similar_products">
    <h3>Similar products</h3>
    <h4>
      <a class="link_to_internal_page" href="/brands/native_union/3_in_1_magnetic_charger">3-in-1 Magnetic Charger</a>
    </h4>
    <p>
      While the 2-in-1 Magnetic Charger only charging options has for a phone
      and earbuds, the 3-in-1 Magnetic Charger can also charger an Apple Watch.
      This charger is built with the same ever-lasting materials, but adds just
      the Apple Watch charger. Read here more about Native Union's
      <a class="link_to_internal_page" href="/brands/native_union/3_in_1_magnetic_charger">3-in-1 Magnetic Charger</a>
    </p>
    <h4>
      <a class="link_to_internal_page" href="/brands/native_union/drop_charger">Drop Charger</a>
    </h4>
    <p>
      Feel like only charging one device at a time? Then the Drop Charger might be the solution for you! With a built in
      LED indicating that your device is charging, you can always have your phone or earbuds ready when you need them.
      Read here more about Native Union's
      <a class="link_to_internal_page" href="/brands/native_union/drop_charger">Drop Charger</a>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return { pictureChoice: "head" };
  },
  methods: {
    changePicture(string) {
      this.pictureChoice = string;
    },
  },
};
</script>