<template>
    <h2>Lightning connector</h2>
    <p>The Lightning connector is a proprietary connection interface created by Apple Inc. for its mobile devices. Introduced in 2012, the Lightning connector replaced the older 30-pin dock connector. It is used to connect Apple mobile devices like iPhones, iPads, and iPods to host computers, external monitors, cameras, USB battery chargers, and other peripherals.</p>
    <figure class="single_picture">
        <img class="single_image" src="../../assets/general/Lightning.webp" alt="" />
        <figcaption>
          &copy;
          <a
            class="link_to_external_page"
            href="https://www.usbmemorydirect.com/blog/usb-a-vs-usb-c/"
            >USB Memory Direct</a
          >, Lightning
        </figcaption>
      </figure>
    <p>One of the key features of the Lightning connector is its compact size, which helps in making devices thinner and lighter. The connector is also reversible, meaning it can be plugged in either way, making it more user-friendly. Despite its small size, the Lightning connector is robust and supports high-speed data transfer and charging.</p>
    <p>Every iPhone between the iPhone 5 (including the 5S and the 5C models) and the iPhone 15 (which has USB-C) have a Lightning connector.</p>
    <p>See chargers with Lightning connectors</p>
    <ul>
        <li><a class="link_to_internal_page" href="/brands/native_union#cables">Cables from Native Union</a></li>
        <li><a class="link_to_internal_page" href="/brands/belkin#cables">Cables from Belkin</a></li>
    </ul>
</template>

<style scoped>
.single_image {
  width: auto;
  height: 50%;
}
</style>