<template>
    <p>Welcome to our comprehensive product catalog! Here, you'll find a wide range of items designed to enhance your
        digital lifestyle. Browse through the handpicked selection of charging cables, sorted by brand to ensure
        compatibility and quality, explore our fast chargers for quick and efficient power-ups, discover the convenience
        of our wireless chargers, and check out various other accessories to complement your devices. Click on the links
        below to delve into each category and find the perfect products to meet your needs.</p>
    <ul>
        <li>
            <a class="link_to_internal_page" href="/types_of_products/charging_cables">Charging Cables</a>
        </li>
        <li>
            <a class="link_to_internal_page" href="/types_of_products/fast_chargers">Fast Chargers</a>
        </li>
        <li>
            <a class="link_to_internal_page" href="/types_of_products/wireless_chargers">Wireless chargers</a>
        </li>
    </ul>
</template>