import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router';
import { createStore } from 'vuex';

import App from './App.vue'
import HomePage from './components/HomePage.vue'
import BrandsPage from './components/BrandsPage.vue'

import NativeUnion from './components/brands/native_union/NativeUnion.vue'
import BeltCable from './components/brands/native_union/BeltCable.vue'
import BeltCableXL from './components/brands/native_union/BeltCableXL.vue'
import NightCable from './components/brands/native_union/NightCable.vue'
import DeskCable from './components/brands/native_union/DeskCable.vue'
import ThreeMagneticCharger from './components/brands/native_union/ThreeMagneticCharger.vue'
import TwoMagneticCharger from './components/brands/native_union/TwoMagneticCharger.vue'
import DropCharger from './components/brands/native_union/DropCharger.vue'

import BelkinPage from './components/brands/belkin/BelkinPage'
import BoostChargeCable from './components/brands/belkin/BoostChargeCable.vue'
import BoostChargeAdapter from './components/brands/belkin/BoostChargeAdapter.vue'

import ApplePage from './components/brands/apple/ApplePage.vue'
import MacBookChargers from './components/brands/apple/MacBookChargers.vue'
import iPhoneChargers from './components/brands/apple/iPhoneChargers.vue'
import AirpodsPro from './components/brands/apple/AirpodsPro.vue';
import AirTag from './components/brands/apple/AirTag.vue';

import FastChargers from './components/types_of_products/FastChargers.vue'
import WirelessChargers from './components/types_of_products/WirelessChargers.vue'
import TypesOfProducts from './components/types_of_products/TypesOfProducts.vue'
import ChargingCables from './components/types_of_products/ChargingCables.vue';

import MFiCertification from './components/functionalities/MFiCertification.vue'
import FunctionalitiesExplained from './components/functionalities/FunctionalitiesExplained.vue'
import LightningConnector from './components/functionalities/LightningConnector.vue'
import USBAandUSBC from './components/functionalities/USBAandUSBC.vue'

import PageNotFound from './components/PageNotFound.vue'

const app = createApp(App);

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: HomePage },

        { path: '/brands', component: BrandsPage },

        { path: '/brands/apple', component: ApplePage },
        { path: '/brands/apple/macbook_chargers', component: MacBookChargers },
        { path: '/brands/apple/iphone_and_ipad_chargers', component: iPhoneChargers },
        { path: '/brands/apple/airtag', component: AirTag },
        { path: '/brands/apple/airpods_pro', component: AirpodsPro },

        { path: '/brands/native_union', component: NativeUnion },
        { path: '/brands/native_union/belt_cable', component: BeltCable },
        { path: '/brands/native_union/belt_cable_XL', component: BeltCableXL },
        { path: '/brands/native_union/night_cable', component: NightCable },
        { path: '/brands/native_union/desk_cable', component: DeskCable },
        { path: '/brands/native_union/3_in_1_magnetic_charger', component: ThreeMagneticCharger },
        { path: '/brands/native_union/2_in_1_magnetic_charger', component: TwoMagneticCharger },
        { path: '/brands/native_union/drop_charger', component: DropCharger },


        { path: '/brands/belkin', component: BelkinPage },
        { path: '/brands/belkin/boostcharge_cable', component: BoostChargeCable },
        { path: '/brands/belkin/boostcharge_adapter', component: BoostChargeAdapter },

        { path: '/types_of_products', component: TypesOfProducts },
        { path: '/types_of_products/fast_chargers', component: FastChargers },
        { path: '/types_of_products/wireless_chargers', component: WirelessChargers },
        { path: '/types_of_products/charging_cables', component: ChargingCables },

        { path: '/functionalities_explained', component: FunctionalitiesExplained },
        { path: '/functionalities_explained/mfi_certification', component: MFiCertification },
        { path: '/functionalities_explained/lightning_connector', component: LightningConnector },
        { path: '/functionalities_explained/usb_a_vs_usb_c', component: USBAandUSBC },

        { path: '/:pathMatch(.*)*', component: PageNotFound },
    ],
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
            }
        }
    }
});

const store = createStore({
    state() {
        return {
            companyName: "Gadget Grove Central",

            // APPLE
            link_to_20W_charger: "https://www.amazon.com/Apple-20W-USB-C-Power-Adapter/dp/B08L5M9BTJ?crid=2IDP9NVRIK92K&dib=eyJ2IjoiMSJ9._QDmQ-k5ppwgrIxdeh4u9a-5RKrziFBwUAfWv7GTyRZIPSMynrTIJTPseJ5HrF9eINAjxg9BcPhWhXBlL7MwvKP53kRjCxh1e5Euovz-KT8-pJLTOiMkp-gY_CNk5JplC-Y_O0pWQWy-JK4fZOPfUvDgmyrgV18FJEaVX75884gJdTKPLMyQt4IeFjOWjlovdA_xUmgiesc3_tcjySLmvlfylDAh8xQTKglAZBt4phk.pDzZZafSOuKOdnEshVFZi3S54EjrAc0yo95eWInorbY&dib_tag=se&keywords=apple+power+adapter+usb+c+20w&qid=1712297303&sprefix=apple+power+adapter+usb+c+20w%2Caps%2C212&sr=8-3&linkCode=ll1&tag=gadgetgrovece-20&linkId=a7c4af7130eab3b8fa42607cfbe0ffb4&language=en_US&ref_=as_li_ss_tl",
            link_to_30W_charger: "https://www.amazon.com/Apple-30W-USB-C-Power-Adapter/dp/B08F9HGJS6?dib=eyJ2IjoiMSJ9.LdhZ-C1F8XvzM04Ift7GevgexOuQa_LqJRhfDl5mglR_PpFXsARwfAucSOXBeE7oPmoJRtrgl6n0z0dwt0p7zvjgOLVhpZFm3-wwp58HZGLJw7y4IDEzeNl9Fe5aVRv6u94tBb0PrCK67Umyw5gHUxFuAYP-MKtl4y2sY8EvqScgDwmQrqrbvO7wtqwcWenY10eLvWaD4C6dUKMiZpxg5qZuYrrMiiozc5WMdXCPB-w.BtVQjcK-NVMsBGDzHtbG5rVV0Uo5785h7bQodD00ypE&dib_tag=se&keywords=apple+30w+usb+c&qid=1712075959&sr=8-3&linkCode=ll1&tag=gadgetgrovece-20&linkId=d66fadcbc883ed2f1d2b6e58a96e7a19&language=en_US&ref_=as_li_ss_tl",
            link_to_70W_charger: "https://www.amazon.com/Apple-70W-USB-C-Power-Adapter/dp/B0C75VLJ4Q?crid=2W080J9SWE2XN&dib=eyJ2IjoiMSJ9.-hbgfxexXOPPHSmnnII9TINV9saU5wAiVFvijbZYBcUuQonotZ28qfBFDoDdBAkGPEtgSfTai-8TQL5jLddbE6WWCW6uxkPl1Frfo3nVvnw-ei8xarVGlXeuQMpAnKFv_Vg1UqCnn_Y6fWncPq4HdS9nZzMsBD_kPgSjgsbvJM-XYQQCYQOc-Dn_BRG-EtcKL9f5a-sDGPc25aLrWXjowLT6CIDzRyt369uT2wzjGn4.XXHERr0_xqZne2jyayOA-fchK3HdGXVJeA-7cLGHRes&dib_tag=se&keywords=apple+power+adapter+usb+c+70w&qid=1712210500&sprefix=apple+power+adapter+usb+c+7%2Caps%2C357&sr=8-3&linkCode=ll1&tag=gadgetgrovece-20&linkId=422c8a7b12ef3733b9561a6d01a7f8ef&language=en_US&ref_=as_li_ss_tl",
            link_to_96W_charger: "https://www.amazon.com/Apple-96W-USB-C-Power-Adapter/dp/B081FVQCHQ?crid=1G7CWRDYGRBEL&dib=eyJ2IjoiMSJ9.rrAgX6TMHF61VULic_blaoQZ-leKzxSUVY_VjwUc5AZvOkhknGV_8vVWiv9EnSWFGeb7fLbQzi42-x7LlCvO6LikJFs3QyEWva4NLElSDlmFamJAidi1xw2vtC5Ump-58KdMZajCX4CooViFWw75u31uKHQBZv1n49GGnnBJLu3LL37_WJgjH78Z5SxQlygWTE8Hn2ryXh73RvK1ANNIkYUgj0vuIj_OVp_4ztkktFw.U51ujn8LxUDiVk1hulrfVuv5LeVuylL611mgk99Ix84&dib_tag=se&keywords=apple+power+adapter+usb+c+90w&qid=1712210442&sprefix=apple+power+adapter+usb+c+9%2Caps%2C444&sr=8-3&linkCode=ll1&tag=gadgetgrovece-20&linkId=da389bbeb12decb546dd44931edb4712&language=en_US&ref_=as_li_ss_tl",
            link_to_140W_charger: "https://www.amazon.com/Apple-140W-USB-C-Power-Adapter/dp/B09JR6DBFH?crid=3PCV5AC7T7K49&dib=eyJ2IjoiMSJ9.ypuMb8MzXz4OldKC_YHuBtVCMXM25M_Ay_wttMCmwW7ySRmRRu_OAxxTDkrZlpiNcWo8wKsiVTBmOQEp8G0Mgl-5pwrnaMiZICJlR790CsBz-dwEggASdU9CahV3LTUs5XXm_jMNv95CTeGTeHvsLUaYQsdaGIayKrl5CwZkxw9HVQ87_-r9TJybKnnX8ql4ZYzeXLdoOoO6a-8Q3qA05bRceB4SYMF37P7p1dHjNYIMeM-JzL4zblUukflm9tp9.n4OposrjaOdXioBtaLX-0-aIRPOYkUsfckNdTMvm_rk&dib_tag=se&keywords=apple+power+adapter+usb+c&qid=1712210407&refinements=p_123%3A110955&rnid=85457740011&s=wireless&sprefix=apple+power+adapter+usb+%2Caps%2C285&sr=1-3&linkCode=ll1&tag=gadgetgrovece-20&linkId=c4a6fe2b2130f2b3b44e6e024159c20e&language=en_US&ref_=as_li_ss_tl",
            link_to_airpods_pro: "https://www.amazon.com/Apple-Generation-Cancelling-Transparency-Personalized/dp/B0CHWRXH8B?dib=eyJ2IjoiMSJ9.TYPwG9IHpeejvv6mK_fI8nIOC3pSJyYcy_1sTqxOfR7ifZFDNVyFCSkgsp0SAu2aECUPmwkRanA8dSRxqkK8ze4eEwn4qeJ3AWvD9IEYEl2zsrPB4iPhTSlO7ykXUDUZGYK8uit1ALO24JZNmrSM4ExjMroyG7kvswVLFpBifJEI32G6yAnLcxSISdU0HmXzmH4y9yfDoUcGmSL7RDFpkIg4AsxSR6-oV3RjcT9g3Cs.AwOxjIusw8LT8_V_pRMuVU31s78o_Qlycpsqdh1I7OE&dib_tag=se&keywords=airpods+pro+2&qid=1713862858&sr=8-1&linkCode=ll1&tag=gadgetgrovece-20&linkId=40918cc357ff7d09e52adc9fcdc04ccd&language=en_US&ref_=as_li_ss_tl",
            link_to_airtag: "https://www.amazon.com/Apple-MX532AM-A-AirTag/dp/B0933BVK6T?&linkCode=ll1&tag=gadgetgrovece-20&linkId=e66466ede968d13d2914d5757865f324&language=en_US&ref_=as_li_ss_tl",

            // NATIVE UNION CABLES
            link_to_belt_cable_A_Lightning: "https://www.amazon.com/Native-Union-Belt-Cable-Ultra-Strong/dp/B071CLQHMT?pd_rd_w=5ATXM&content-id=amzn1.sym.615d9037-0d71-4715-8421-5695fbf26656&pf_rd_p=615d9037-0d71-4715-8421-5695fbf26656&pf_rd_r=98PNQPEQXSCPB75KQ4Y1&pd_rd_wg=MSVn3&pd_rd_r=05660cd2-16cf-422a-b956-70cdc9f2cd70&pd_rd_i=B071CLQHMT&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=10d7c43276709d76305b01e6027e62aa&language=en_US&ref_=as_li_ss_tl",
            link_to_belt_cable_A_C: "https://www.amazon.com/Native-Union-Belt-Cable-USB-C/dp/B077YN64ZB?content-id=amzn1.sym.6630a0ac-0b43-4c44-aa9a-a6c59ce0024b%3Aamzn1.sym.6630a0ac-0b43-4c44-aa9a-a6c59ce0024b&crid=3O7HONDAS6WOX&cv_ct_cx=native%2Bunion%2Bbelt%2Bcable&dib=eyJ2IjoiMSJ9.ml1XLE9FczIqV6MjvGYwRxjkfI6zNBz3wkAXJ7E0xxTBitM-Z3jszsQeSNWVQKqzihaWtevag2NxlxCmOm6euA.rpNTG1E_6vF8VU4TRHGehYgmIc9aSbKeZYwcIvIuKzQ&dib_tag=se&keywords=native%2Bunion%2Bbelt%2Bcable&pd_rd_i=B077YN64ZB&pd_rd_r=85fb324d-3688-4c91-b5a6-02f9cdb68002&pd_rd_w=4KygC&pd_rd_wg=SDE4f&pf_rd_p=6630a0ac-0b43-4c44-aa9a-a6c59ce0024b&pf_rd_r=F94A5185FE3ER90VQ7SZ&qid=1709229763&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sprefix=native%2Bunion%2Bbelt%2Bcab%2Caps%2C233&sr=1-3-364cf978-ce2a-480a-9bb0-bdb96faa0f61-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9zZWFyY2hfdGhlbWF0aWM&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=3fc329b5c4c49887d82d2689a2c12838&language=en_US&ref_=as_li_ss_tl",
            link_to_belt_cable_C_Lightning: "https://www.amazon.com/NATIVE-UNION-Cable-USB-C-Lightning/dp/B07ZKDMRXF?crid=3O7HONDAS6WOX&dib=eyJ2IjoiMSJ9.tUezRHlIdJMWILwOsSnpnKCygSMvQg7yzQ-Hyd1eFNnwIzSm3V-NgdapRVDrpjJOjp9408hLbrI74xHGwp-8tjlDlm1oqKtcJYmVJpD9b-esfGSH02b3B2jDhL4H50P6vT54Vy6pqCiUx7y-lRrKlnXIUlTeVmQ_Wayw1aaBNcpo7EGMlOuZfc-qkdJ2fmirjcugKLFV8R-0BOkTwQNUCPhZXkA1S3oQV36Ld8_hFXU.rIi8qKZwWvQDDEmUYce-4EIHo79eQ-RaCvkqtIV6Rzw&dib_tag=se&keywords=native%2Bunion%2Bbelt%2Bcable&qid=1709229763&sprefix=native%2Bunion%2Bbelt%2Bcab%2Caps%2C233&sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=f1b03e5426e16eff53ce1d52f230c9f3&language=en_US&ref_=as_li_ss_tl",
            link_to_belt_cable_C_C: "https://www.amazon.com/Native-Union-Ultra-Strong-Compatible-2018-2020/dp/B086QQDV2X?content-id=amzn1.sym.6630a0ac-0b43-4c44-aa9a-a6c59ce0024b%3Aamzn1.sym.6630a0ac-0b43-4c44-aa9a-a6c59ce0024b&crid=3O7HONDAS6WOX&cv_ct_cx=native%2Bunion%2Bbelt%2Bcable&dib=eyJ2IjoiMSJ9.ml1XLE9FczIqV6MjvGYwRxjkfI6zNBz3wkAXJ7E0xxTBitM-Z3jszsQeSNWVQKqzihaWtevag2NxlxCmOm6euA.rpNTG1E_6vF8VU4TRHGehYgmIc9aSbKeZYwcIvIuKzQ&dib_tag=se&keywords=native%2Bunion%2Bbelt%2Bcable&pd_rd_i=B086QQDV2X&pd_rd_r=85fb324d-3688-4c91-b5a6-02f9cdb68002&pd_rd_w=4KygC&pd_rd_wg=SDE4f&pf_rd_p=6630a0ac-0b43-4c44-aa9a-a6c59ce0024b&pf_rd_r=F94A5185FE3ER90VQ7SZ&qid=1709229763&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sprefix=native%2Bunion%2Bbelt%2Bcab%2Caps%2C233&sr=1-5-364cf978-ce2a-480a-9bb0-bdb96faa0f61-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9zZWFyY2hfdGhlbWF0aWM&smid=A1U2KJLTA1ATV5&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=be40f8560ba85e9c306feda439585e3b&language=en_US&ref_=as_li_ss_tl",
            link_to_belt_cable_XL_A_Lightning: "https://www.amazon.com/Native-Union-Belt-Cable-Ultra-Strong/dp/B06ZZY7TZN?dib=eyJ2IjoiMSJ9.ngUfJ1DJ7-y4_AdNRHTBtR2tJngc2XhX_3Gzppou4JRPTleIjQM-gk_mfiapL2MfsZfxH48EyR4ViHzUBeojxc6ggvsWeTGE4YXvA0zzhBl4iZ84sHbVNx_SFv7OgMXGif6TptxOirtny5lNPHmXyg.Ln4DCgxqT1yBUY-n2M_FB5vs987jdpl9aLeReUFUeMw&dib_tag=se&keywords=native%2Bunion%2Bbelt%2Bcable%2BxL&qid=1711209971&sr=8-5&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=cc31accb46ca836735729296c5fcde1a&language=en_US&ref_=as_li_ss_tl",
            link_to_belt_cable_XL_A_C: "https://www.amazon.com/Native-Union-Belt-Cable-USB-C/dp/B0725FC67K?dib=eyJ2IjoiMSJ9.ngUfJ1DJ7-y4_AdNRHTBtR2tJngc2XhX_3Gzppou4JRPTleIjQM-gk_mfiapL2MfsZfxH48EyR4ViHzUBeojxc6ggvsWeTGE4YXvA0zzhBl4iZ84sHbVNx_SFv7OgMXGif6TptxOirtny5lNPHmXyg.Ln4DCgxqT1yBUY-n2M_FB5vs987jdpl9aLeReUFUeMw&dib_tag=se&keywords=native%2Bunion%2Bbelt%2Bcable%2BxL&qid=1711209971&sr=8-6&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=a080725ea1ba3915d8ecf6a89ca47bf4&language=en_US&ref_=as_li_ss_tl",
            link_to_belt_cable_XL_C_Lightning: "https://www.amazon.com/NATIVE-UNION-Cable-USB-C-Lightning/dp/B082TZ7ZCQ?dib=eyJ2IjoiMSJ9.ngUfJ1DJ7-y4_AdNRHTBtR2tJngc2XhX_3Gzppou4JT7u4BSzLcX5ZD3KY9OILyaj0gakebOwM4_wh9qQqGVfo0q-kAcxDcI7cM1Y6pvOnACxG_wdcq2yClzgYFiliarif6TptxOirtny5lNPHmXyg.93IPX3rraACW1zQ1jpMhzIta5jLlR6p5MBtm-ENE7mw&dib_tag=se&keywords=native%2Bunion%2Bbelt%2Bcable%2BxL&qid=1709708549&sr=8-4&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=91ff50eaea39a1ca63c32b2236d4c7db&language=en_US&ref_=as_li_ss_tl",
            link_to_belt_cable_XL_C_C: "https://www.amazon.com/Native-Union-Type-C-Belt-Cable/dp/B086QQTVT9?crid=3P258DODH8T0F&dib=eyJ2IjoiMSJ9.hJRD8gxXiSAdMg3gvF73f5rIjcq1yxbu9ot3s7Qc0gKWsw1xYrJU-tv7imgCJ6G64aqoVC6abDd4nIt2nJ8bmU1CAweT2c6DYW7OEyRfjwgsfp8KXzrlGRAXN7UlUHr28FD020fKYBtIriuiPKKNwwNzf_ALqNBDvS_XWqA3jeo.IgitdDro4Z-VLsP5f9mrjt_ei5omqxornyMuti2P8oQ&dib_tag=se&keywords=native%2Bunion%2Bbelt%2Bcable%2BxL%2Busb%2Bc&qid=1711210013&sprefix=native%2Bunion%2Bbelt%2Bcable%2Bxl%2Busb%2Bc%2Caps%2C254&sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=de7cee5de62810e6822ddc7d1f0ba93b&language=en_US&ref_=as_li_ss_tl",
            link_to_night_cable_A_Lightning: "https://www.amazon.com/Native-Union-Night-Cable-Ultra-Strong/dp/B0747NLF6M?crid=7H9CL44MS83P&dib=eyJ2IjoiMSJ9.AcyM-YVdpISRgBxj2R3s13FnhjYqpBSQ9LV0k-F8wdj46IPdCg2ZtGYi7nAfy9VYyKG-xsguQfnfIhJQmes1tSj80OXwGzMrFoci_S_DdgYuZtXfu4YB09BLucpnec74uEr2eTsZFkmkmhuM7EPFWT5FdBNYy3mb7WJZWxXzKJNW9KmRXQEiVoCwK6V_QzVrmSi4oOiOMIFV6MyH_Ur4HJwMHYjJD7Hre8P4PyUQ1dE.hs0mqN1wOaYpIQ7kBduGhaZYtAMlGpInY28qw8v4OUo&dib_tag=se&keywords=native%2Bunion%2Bnight%2Bcable&qid=1712049231&sprefix=native%2Bunion%2Bnight%2Caps%2C465&sr=8-3&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=f910fcd5b81c6e15afaf84086d214a88&language=en_US&ref_=as_li_ss_tl",
            link_to_night_cable_C_Lightning: "https://www.amazon.com/dp/B083SH8HPW?_encoding=UTF8&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=58c31ab941f68dbc25cdc656a1a51dc8&language=en_US&ref_=as_li_ss_tl",
            link_to_desk_cable: "https://www.amazon.com/Native-Union-Type-C-Desk-Cable/dp/B0924Y3KVN?dib=eyJ2IjoiMSJ9.OgfJg8G9DEt4HZv5s03OUyC3tiOiAAvUWMqAtFLHg_FjtHuKxTmcYs-gX6hvdIBytKwhvdJ0KbTiG_qEisMYlxfD0XXmqRFcVibUJcY0-45etk7VSjF55XKRFcdAd3U8wVwhlsmrBkkHKtrsCaR2mncby5TKNIerWCtvb5VS5ZOAcpfexGII6WFaf1xOlpZTIKwM4eQRBJo_-zWz4YNLrYK-UTqTPNXZzrZRBNzFasY.IBrP5361nAYyjTLwGrRK0mN64GnL-WJS9RS0a4WBq8Q&dib_tag=se&keywords=native%2Bunion%2Bdesk%2Bcable&qid=1711891396&sr=8-3&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=8c3fb6d662d4e3cd42b34c983af6def8&language=en_US&ref_=as_li_ss_tl",
            link_to_3_in_1_magnetic_charger: "https://www.amazon.com/Native-Union-Magnetic-Wireless-Charger/dp/B09N42HVJ1?crid=2AOOVTFTRDOT7&dib=eyJ2IjoiMSJ9.At6Z7tz3V80IJFUqEYBZEGUfwA61BMVyBv1oAFtO12LF5upMYmess9_JXuBwg58lqXX1QWbehrJc7E-zwYwxO6Xk5pLLcFTPmq92Qjdyx89XCgpcxgbnTdBLCB0bjIoUQKm_8SMBytOb4_9VaIu0XNtk8YJ-YGoMypj8aExaVbE7KRimj8o-P1on2B4PQgoeGAS_xSyJ8hvLm33iRLsSQQBwehb1plnJyH7I9nabAhs.pjtjmgaBWIzFkecjR5VGoRfPVfrBJHH-vQHjUsUcvJ0&dib_tag=se&keywords=native%2Bunion%2Bsnap%2B3-in-1%2Bmagnetic%2Bwireless%2Bcharger&qid=1712061308&sprefix=native%2Bunion%2B3%2Bin%2B1%2B%2Caps%2C265&sr=8-3&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=aa4e31bf172d188372dcdc6e6f4c93f7&language=en_US&ref_=as_li_ss_tl",
            link_to_2_in_1_magnetic_charger: "https://www.amazon.com/Native-Union-Magnetic-Wireless-Charger/dp/B09DBRQHGH?dib=eyJ2IjoiMSJ9.dNaOclLIb1Cq58nxxrxvLgQ_1VHSkWAO1zI-PW-ZgYoD_aygz1KgiJw5Cte9NKp6TfsmokowXhIoxltEarht8Ids06Zn_Te67ziG8ikPaVKmAKWom4hU1cl4zOXcBGR3bSt0JaPr4rWlHUgQYPCClw.7fNzE9PYdRBXLQZT7-fclcZZtcukzLFic6ZF0YR4_f8&dib_tag=se&keywords=native+union+snap+2-in-1+magnetic+wireless+charger&qid=1712061334&sr=8-3&linkCode=ll1&tag=gadgetgrovece-20&linkId=f350cc2dcf3360f211b7ef581ac49163&language=en_US&ref_=as_li_ss_tl",
            link_to_drop_charger: "https://www.amazon.com/Native-Union-Drop-Wireless-Charger/dp/B07SZG66WP?crid=GVHYVBC8ZQGH&dib=eyJ2IjoiMSJ9.T09N3SnAQMDTdd-DnQ02iL1xJU4iAoXMPvkuWvwD7YRzyuOSxnXC6dNsxPs3pfLOQPPK_qZ0HPm_bk6e1Bf71uwaepmZOYTSSY3d2JTrbeYucynicFBuplHSGS-mJ6Ql7sIuJ768XeVkwmB0O_0NOiEMHbC2SZIfhe9V_Max-D7-7QNdpY4hPyEvGNZ1LVasVGzzvzXeS5XS-NQ2bXH5aY62MZqnQwLIHMX_ECWP7Vw.KxxoP3SnWxjLJO1UzazZ9tg7cAFMPcWDuyPPl8zdiLw&dib_tag=se&keywords=native%2Bunion%2Bdrop%2Bcharger&qid=1712069037&sprefix=native%2Bunion%2Bdrop%2Bcharge%2Caps%2C594&sr=8-3&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=0514b2f8d12408926834d0e3a391ea1c&language=en_US&ref_=as_li_ss_tl",

            // BELKIN
            link_to_boostcharge_cable_USB_C: "https://www.amazon.com/Belkin-Lightning-Charging-MFi-Certified-CAA004bt1MWH/dp/B085582852?crid=BG90XG7L34X9&dib=eyJ2IjoiMSJ9.B0ZzdUOEPCaTuIF6Es-63hnSXuAOW97oGzjx1GSojL0u0syDYIaSFPNevOl2bsC2N9ejexbqN7XgD8T32tbuanynesFyqqJMumqxi-rMhSK0zECeG2oRFeuD7SBlJHlbeTqYc4hMVnZZxubbQA_LIeLyzkg-n1s17mNEWiLwdrEWvQxuq-Ox8CinlOvkdY8TPnARGyNgR2I5l-37bcm695hNEr7Afm1X8-D2ZJIZT4w.IGT9aQwHCvFfWrNisL2TIWjKjP7bOJuAeJVuZFCRVKM&dib_tag=se&keywords=belkin%2Bboost%2Bcharge%2Bcable%2Busb%2Bc%2Blightning%2Bbraided&qid=1712586116&sprefix=belkin%2Bboost%2Bcharge%2Bcable%2Busb%2Bc%2Blightning%2Bbraide%2Caps%2C243&sr=8-1&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=771d6fc4f97723c3d76a217a90efd4e1&language=en_US&ref_=as_li_ss_tl",
            link_to_boostcharge_cable_USB_A: "https://www.amazon.com/Belkin-Lightning-MFi-Certified-Charging-CAA002bt2MWH/dp/B086H3YDVM?crid=3UCWWWSPRB5WA&dib=eyJ2IjoiMSJ9.B0ZzdUOEPCaTuIF6Es-63sqORje64up5FIyz4sA4bhDz73UAWneekICLOlrJc7aMMFtAG_HY0EAZAQbMQ0I07q4UshQAUGRKFny1g4K8WT_qZUTiKLJOeKJ_eYOwh_1rN8N_4CAmpLbk_kDrqezaGcY_fOFhp1aPaZKKFURu5ZAvQWgXoDOtiHb0jqG8ua8uK3B3q0huB6YQDvWH9fnCHeEF-e5R9vBi5f2L4mF4iRU.t7h7Y52GZWEHFbGOw0718wE4O35JdP2k27T9Wi23-Vo&dib_tag=se&keywords=belkin%2Bboost%2Bcharge%2Bcable%2Busb%2Bc%2Blightning&qid=1712585731&sprefix=belkin%2Bboost%2Bcharge%2Bcable%2Busb%2Blightning%2Caps%2C246&sr=8-8&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=864c1c7cf100f6d275c01a98179656ca&language=en_US&ref_=as_li_ss_tl",
            link_to_boostcharge_adapter_24W: "https://www.amazon.com/Belkin-Charger-iPhone-Ultra-Pixel/dp/B089JJ7VLS?crid=3BK63Z80MLHNT&dib=eyJ2IjoiMSJ9.48dBVzBeKIhrM4JH6uny7sKKbwFIkVHZAAmZsxo5lCDQgpJ2SkpNett_V7warJDLOl_eesn9l8g7bHWzGGib25s-9c0ZYA2QjsGwEGbSbBsVUKLA-i1mUmvKxzgQ4hw0GBIzmurM_7CeL-G8THTXxO-5MEA-Ehwmstm2VYiYxwx0xZJaI2h5K0XSYAvRmTWnfZZ8pxl1MtoJP7JybIkewvLJ4pQqMPsCF-8EyliAJfA.eNp0yBcA9p4cUb_TCS5y8AUrkkuK4FKBIGeeDUevlNg&dib_tag=se&keywords=boost+charge+adapter+24w+belkin&qid=1712594817&sprefix=boost+charge+adapter+24w+belkin%2Caps%2C206&sr=8-9&linkCode=ll1&tag=gadgetgrovece-20&linkId=e928259da70eae95fbe06ba61b42d8fa&language=en_US&ref_=as_li_ss_tl",
            link_to_boostcharge_adapter_25W: "https://www.amazon.com/Belkin-Delivery-Charger-Adapter-Enabled/dp/B092PRSQMX?crid=ECC876DIWH53&dib=eyJ2IjoiMSJ9.dRrV8pNg9fmSZiYDcvYcwngZismYihdtGDIRKuqcKk9hyOzgSZ8fxTOEyRpKXJcs4siD-Huv3d-7LBn-BtmGssEzIYvw4qgSRgqybXZLjsDnu32zbn3etQmqQuxUIyM7RUlHmpYw_zK-wh0zK3zNyg_Zvz9_mYHwo75-wB1kwWii-_Jq3XoIuDSfEl4M8U6x9bCAv_b6ZOCrUXgZjrwxXpSrG-nX8XvQfYpLiaBPzP0.d0Jb-DIz8c64zaY5Tkaru6PPjRGdyjYi23U-IKKWGsU&dib_tag=se&keywords=boost%2Bcharge%2Badapter%2B25w%2Bbelkin%2Busb-c&qid=1712595409&sprefix=boost%2Bcharge%2Badapter%2B25w%2Bbelkin%2Busb-c%2Caps%2C247&sr=8-1&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=652a50688b13ecd248d958bb91fbf2fb&language=en_US&ref_=as_li_ss_tl",
            link_to_boostcharge_adapter_37W: "https://www.amazon.com/Belkin-Charger-Delivery-Charging-Galaxy/dp/B09LX4S9R7?crid=11PVBTQ7AX0LI&dib=eyJ2IjoiMSJ9.AzBe_Qx66kyEFsjDOZfFyrhI-C6Jy8BgGgZKOkpwBT6n5hqGvv5y9uXyxUH54AzJywkv_6S6M54_8jpkdkrIrGnBtH2HHMizuylFmFmzmm5OTy6jnB6T8i2pdDZL301UOa5aOa9pcZxn3TYXUsAAC3BI7zm8dzMp1LsA7qJp5dBwYQhe6YwonR_mQWlFNBdT1PiHNP5pgsu0B0W4mN1I3FV0Fx6olmMNlGr_JxybdZI.4Lvud85Sg0BqUXilOiiOPwXD1c0ADvvZ0Bugt51Fiys&dib_tag=se&keywords=boost%2Bcharge%2Badapter%2B25w%2Bbelkin&qid=1712594952&sprefix=boost%2Bcharge%2Badapter%2B25w%2Bbelkin%2Caps%2C301&sr=8-1&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=af8b5ccd461ed9d43da6858ea0b46513&language=en_US&ref_=as_li_ss_tl",
            link_to_boostcharge_adapter_67W: "https://www.amazon.com/Belkin-BoostCharge-Charger-Enabled-Charging/dp/B0CQPR4GJX?crid=3BK63Z80MLHNT&dib=eyJ2IjoiMSJ9.48dBVzBeKIhrM4JH6uny7sKKbwFIkVHZAAmZsxo5lCDQgpJ2SkpNett_V7warJDLOl_eesn9l8g7bHWzGGib25s-9c0ZYA2QjsGwEGbSbBsVUKLA-i1mUmvKxzgQ4hw0GBIzmurM_7CeL-G8THTXxO-5MEA-Ehwmstm2VYiYxwx0xZJaI2h5K0XSYAvRmTWnfZZ8pxl1MtoJP7JybIkewvLJ4pQqMPsCF-8EyliAJfA.eNp0yBcA9p4cUb_TCS5y8AUrkkuK4FKBIGeeDUevlNg&dib_tag=se&keywords=boost%2Bcharge%2Badapter%2B24w%2Bbelkin&qid=1712594817&sprefix=boost%2Bcharge%2Badapter%2B24w%2Bbelkin%2Caps%2C206&sr=8-5&th=1&linkCode=ll1&tag=gadgetgrovece-20&linkId=57f2113127b389c2dbcfa893d1234d12&language=en_US&ref_=as_li_ss_tl",
        }
    }
})

app.use(router)
app.use(store)
app.mount('#app')
