<template>
  <h1>Welcome to {{ $store.state.companyName }}!</h1>
  <section class="navigation">
    <NavigationMenu />
  </section>
  <section class="router">
    <PathBar />
    <router-view />
  </section>
</template>

<script>
import NavigationMenu from "../components/NavigationMenu.vue";
import PathBar from "../components/PathBar.vue";

export default {
  components: {
    NavigationMenu,
    PathBar,
  },
};
</script>

<style scoped>
h1 {
  color: #fafafc;
}

section {
  background-color: #28293d;
  border-radius: 5px;
  margin-top: 20px;
}

.router {
  padding: 0 20px 10px 20px;
}
</style>