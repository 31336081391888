<template>
  <div name="title">
    <h2>Introducing the Native Union Desk Cable</h2>
  </div>
  <div name="content">
    <p>
      Are you tired of constantly having to rearrange your workspace to
      accommodate short, inflexible charging cables? Or are you tired of the
      cable falling behind the desk when you unplug your device? Say goodbye to
      these frustrations with the Native Union Desk Cable. Designed with
      convenience and versatility in mind, this premium cable offers a reliable
      solution for keeping your devices charged and your workspace organized.
    </p>
    <p>
      The Native Union Desk Cable is here to solve these problems and more.
      Crafted with superior materials and thoughtful design, this cable is
      engineered to enhance your charging experience while complementing your
      workspace aesthetics.
    </p>

    <div class="images_and_colors">
      <div name="content_images">
        <div class="head_image_div">
          <img
            v-if="this.pictureChoice == 'cosmos'"
            class="head_image"
            src="../../../assets/native_union/desk_cable/desk_cable_cosmos_USB_C_USB_C.webp"
            alt="Desk Cable Cosmos"
          />
          <img
            v-else-if="this.pictureChoice == 'sage'"
            class="head_image"
            src="../../../assets/native_union/desk_cable/desk_cable_sage_USB_C_USB_C.webp"
            alt="Desk Cable Sage"
          />
        </div>
      </div>
      <div name="available_colors" class="available_colors_parent">
        <h3 class="available_colors_text">Available colors:</h3>
        <div class="available_colors" name="A_lightning_colors">
          <img
            class="available_colors_image"
            src="../../../assets/native_union/desk_cable/desk_cable_cosmos_USB_C_USB_C.webp"
            alt="Desk Cable Cosmos"
            :class="{
              available_colors_image_active: pictureChoice == 'cosmos',
            }"
            @click="changePicture('cosmos')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/native_union/desk_cable/desk_cable_sage_USB_C_USB_C.webp"
            alt="Desk Cable Sage"
            :class="{
              available_colors_image_active: pictureChoice == 'sage',
            }"
            @click="changePicture('sage')"
          />
        </div>
      </div>
    </div>
    <p></p>
    <div name="features" class="product_features">
      <h3>Product features</h3>
      <ol>
        <li>
          <b>Integrated Weighted Knot:</b> Keep your cable securely in place and
          prevent it from slipping off your desk with the integrated weighted
          knot. Say goodbye to cables that constantly slide around and disrupt
          your workflow.
        </li>
        <li>
          <b>Optimal Length:</b> Enjoy the perfect balance of freedom and
          convenience with the Desk Cable's 8-foot (2,5-meter) length. Whether
          you're working at your desk, lounging on the couch, or sitting at a
          coffee shop, this cable provides ample reach without unnecessary
          tangles or restrictions.
        </li>
        <li>
          <b>Flexible Construction:</b> The Desk Cable features a flexible yet
          durable design that allows for easy maneuverability and hassle-free
          storage. Say goodbye to stiff, unwieldy cables that refuse to
          cooperate.
        </li>
        <li>
          <b>Premium Materials:</b> Crafted with high-quality materials,
          including braided nylon and reinforced connectors, the Desk Cable
          offers enhanced durability and longevity. Rest assured knowing that
          your cable can withstand the rigors of daily use.
        </li>
        <li>
          <b>Universal Compatibility:</b> Equipped with a USB-C connector on
          both ends, the Desk Cable offers universal compatibility with a wide
          range of devices, including smartphones, tablets, and more.
        </li>
      </ol>
      <p>
        Upgrade your charging setup and elevate your workspace aesthetics with
        the
        <a
          class="link_to_external_page"
          :href="$store.state.link_to_desk_cable"
          >Native Union Desk Cable</a
        >.
      </p>
    </div>
    <div name="personal_opinion" class="personal_opinion">
      <h3>Personal opinion</h3>
      <p>
        Before I bought this cable, every time I would unplug my MacBook, the
        charging cable would slip behind the desk and fall on the ground.
        Solving this required having to move my desk and fumble the cable back
        onto the desk from underneath. I tried to fix this with a piece of tape,
        but eventually this one would loosen up and still take the cable down.
        Native Union fixed this problem for me, thanks to their Desk Cable.
      </p>
    </div>
    <div name="CTA_div" class="CTA_div">
      <a class="button" :href="$store.state.link_to_desk_cable">Shop Now</a>
    </div>
  </div>
  <div name="similar_products" class="similar_products">
    <h3>Similar products</h3>
    <h4>
      <a href="/brands/native_union/belt_cable_XL" class="link_to_internal_page"
        >Belt cable XL</a
      >
    </h4>
    <p>
      The Belt Cable is the same cable as the Desk Cable, but without the
      weighted knot. This knot ensures that the cable won't fall of off your
      desk. However, for a portable cable or when the cable can't fall off of
      anything, the knot doesn't really matter. Also, this cable has more
      connectivity options than the desk cable. Read more about Native Union's
      <a href="/brands/native_union/belt_cable_XL" class="link_to_internal_page"
        >Belt cable XL</a
      >.
    </p>
    <h4>
      <a href="/brands/native_union/night_cable" class="link_to_internal_page"
        >Night cable</a
      >
    </h4>
    <p>
      The Night Cable is the same cable as the Desk Cable and also with a
      weighted knot, just a different shape and more connectivity options. This
      knot ensures that the cable won't fall of off your nightstand and won't
      leave you searching in the dark for the charging cable. Read more about
      Native Union's
      <a href="/brands/native_union/night_cable" class="link_to_internal_page"
        >Night cable</a
      >.
    </p>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      pictureChoice: "cosmos",
    };
  },
  methods: {
    changePicture(string) {
      this.pictureChoice = string;
    },
  },
};
</script>