<template>
  <h2 class="brand_title">Belkin - Be ready for Today</h2>
  <div class="brand_mission">
    <p>
      Belkin's passion is to create innovative technology solutions that enhance
      everyday life. With a relentless commitment to quality and user-centric
      design, Belkin strives to enrich experiences through seamless connectivity
      and smart functionality. Their mission is to empower people through
      cutting-edge products that simplify and elevate digital lifestyles.
    </p>
    <p>
      At Belkin, they believe in fostering connectivity and convenience,
      ensuring their products seamlessly integrate into modern living. The
      dedication to sustainability drives them to minimize environmental impact
      while maximizing performance. Join Belkin on their journey to redefine
      technology and inspire meaningful connections worldwide. Read more about
      Belkin's mission
      <a
        class="link_to_external_page"
        href="https://www.belkin.com/sg/clp-about-us.html"
        >here</a
      >.
    </p>
  </div>
  <div class="products">
    <h3 id="cables" class="products_type">Cables</h3>
    <div class="product">
      <h4 class="product_title">
        <a href="/brands/belkin/boostcharge_cable" class="link_to_internal_page"
          >BoostCharge Cable</a
        >
      </h4>
      <div class="product_details">
        <img
          class="product_page_image"
          src="../../../assets/belkin/boostcharge_cable/boostcharge_cable_USB_A_black.webp"
          alt=""
        />
        <p class="product_page_text">
          Introducing the BoostCharge Cable by Belkin, a versatile and durable
          charging solution designed for all your devices. This cable features a
          robust construction with reinforced connectors, ensuring reliable
          performance and longevity. Enjoy fast charging and data transfer
          capabilities, perfect for smartphones, tablets, and other compatible
          devices. Say goodbye to tangled cables and experience seamless
          charging with the Belkin BoostCharge Cable. Learn more about the
          BoostCharge Cable by Belkin
          <a class="link_to_internal_page" href="/brands/belkin/boostcharge_cable"
            >here</a
          >.
        </p>
      </div>
    </div>
  </div>
  <div class="products">
    <h3 id="cables" class="products_type">Adapters</h3>
    <div class="product">
      <h4 class="product_title">
        <a href="/brands/belkin/boostcharge_adapter" class="link_to_internal_page"
          >BoostCharge Adapter</a
        >
      </h4>
      <div class="product_details">
        <img
          class="product_page_image"
          src="../../../assets/belkin/boostcharge_adapter/boostcharge_adapter_24W_main.webp"
          alt=""
        />
        <p class="product_page_text">
          Introducing the Belkin BoostCharge Adapter, a powerful charging
          solution offering multiple versions tailored to your needs. Choose
          from the 24W, 25W, 37W, or 67W models to suit your device's charging
          requirements. Enjoy fast and efficient charging with these compact and
          versatile adapters. Experience superior performance and reliability
          with Belkin's BoostCharge Adapter lineup. Explore the various versions
          of the BoostCharge Adapter by Belkin
          <a class="link_to_internal_page" href="/brands/belkin/boostcharge_adapter"
            >here</a
          >
        </p>
      </div>
    </div>
  </div>
</template>