<template>
  <div name="title">
    <h2>MacBook Chargers</h2>
  </div>
  <div name="changeProductButtons" class="changeProductOnPageDiv">
    <button
      :class="{ active: chargerChoice == '30W' }"
      class="changeProductOnPageButton"
      @click="changePage('30W')"
    >
      30W
    </button>
    <button
      :class="{ active: chargerChoice == '70W' }"
      class="changeProductOnPageButton"
      @click="changePage('70W')"
    >
      70W
    </button>
    <button
      :class="{ active: chargerChoice == '96W' }"
      class="changeProductOnPageButton"
      @click="changePage('96W')"
    >
      96W
    </button>
    <button
      :class="{ active: chargerChoice == '140W' }"
      class="changeProductOnPageButton"
      @click="changePage('140W')"
    >
      140W
    </button>
  </div>
  <div name="content">
    <p>
      Since around 2016, when Apple came out with their first USB-C MacBook,
      most chargers have changed to use USB-C as well. There are different
      versions of the USB-C charger now, mostly due to different MacBooks being
      available. While all chargers can charge any type of MacBook, one will be
      slower or faster than the other one. The general rule of thumb is as
      follows: use a charger with less Watt (the W after each number) than Apple
      suggests? You get slower charging. If you take a higher one, this means
      faster charging, but to a certain limit. Below are Apple's suggestions for
      the chargers. 'Fast charging' means here that the device will charge from
      0 to 50 percent in about 30 minutes.
    </p>
    <ul>
      <li>
        <b>30W adapter: </b>Used for the MacBook Air and the Apple Vision Pro.
      </li>
      <li>
        <b>70W adapter: </b>Used for the 13 or 15 inch MacBook Air with M2 for
        fast charging. Use it with the 13 or 14 inch MacBook Pro for regular
        charging.
      </li>
      <li>
        <b>96W adapter: </b>Used for the 14 inch MacBook Pro for fast charging.
      </li>
      <li>
        <b>140W adapter: </b>Used for the 16 inch MacBook Pro for fast charging.
      </li>
    </ul>

    <div class="images_and_colors">
      <div name="content_images">
        <div class="head_image_div" v-if="chargerChoice == '30W'">
          <img
            v-if="this.pictureChoice == 'side'"
            class="head_image"
            src="../../../assets/apple/chargers/30W_charger_side.webp"
            alt="MacBook Charger 30W"
          />
          <img
            v-else-if="this.pictureChoice == 'front'"
            class="head_image"
            src="../../../assets/apple/chargers/30W_charger_front.webp"
            alt="MacBook Charger 30W"
          />
          <img
            v-else-if="this.pictureChoice == 'back'"
            class="head_image"
            src="../../../assets/apple/chargers/30W_charger_back.webp"
            alt="MacBook Charger 30W"
          />
        </div>
        <div class="head_image_div" v-else-if="chargerChoice == '70W'">
          <img
            v-if="this.pictureChoice == 'side'"
            class="head_image"
            src="../../../assets/apple/chargers/70W_charger_side.webp"
            alt="MacBook Charger 70W"
          />
          <img
            v-if="this.pictureChoice == 'front'"
            class="head_image"
            src="../../../assets/apple/chargers/70W_charger_front.webp"
            alt="MacBook Charger 70W"
          />
          <img
            v-if="this.pictureChoice == 'back'"
            class="head_image"
            src="../../../assets/apple/chargers/70W_charger_back.webp"
            alt="MacBook Charger 70W"
          />
        </div>
        <div class="head_image_div" v-else-if="chargerChoice == '96W'">
          <img
            v-if="this.pictureChoice == 'side'"
            class="head_image"
            src="../../../assets/apple/chargers/96W_charger_side.webp"
            alt="MacBook Charger 96W"
          />
          <img
            v-if="this.pictureChoice == 'front'"
            class="head_image"
            src="../../../assets/apple/chargers/96W_charger_front.webp"
            alt="MacBook Charger 96W"
          />
          <img
            v-if="this.pictureChoice == 'back'"
            class="head_image"
            src="../../../assets/apple/chargers/96W_charger_back.webp"
            alt="MacBook Charger 96W"
          />
        </div>
        <div class="head_image_div" v-else-if="chargerChoice == '140W'">
          <img
            v-if="this.pictureChoice == 'side'"
            class="head_image"
            src="../../../assets/apple/chargers/140W_charger_side.webp"
            alt="MacBook Charger 140W"
          />
          <img
            v-if="this.pictureChoice == 'front'"
            class="head_image"
            src="../../../assets/apple/chargers/140W_charger_front.webp"
            alt="MacBook Charger 140W"
          />
          <img
            v-if="this.pictureChoice == 'back'"
            class="head_image"
            src="../../../assets/apple/chargers/140W_charger_back.webp"
            alt="MacBook Charger 140W"
          />
        </div>
      </div>
      <div name="available_colors" class="available_colors_parent">
        <h3 class="available_colors_text">Available images:</h3>
        <div
          class="available_colors"
          name="A_lightning_colors"
          v-if="this.chargerChoice == '30W'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/apple/chargers/30W_charger_side.webp"
            alt="Apple Charger 30W"
            :class="{ available_colors_image_active: pictureChoice == 'side' }"
            @click="changePicture('side')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/apple/chargers/30W_charger_front.webp"
            alt="Apple Charger 30W"
            :class="{ available_colors_image_active: pictureChoice == 'front' }"
            @click="changePicture('front')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/apple/chargers/30W_charger_back.webp"
            alt="Apple Charger 30W"
            :class="{
              available_colors_image_active: pictureChoice == 'back',
            }"
            @click="changePicture('back')"
          />
        </div>
        <div
          class="available_colors"
          name="A_lightning_colors"
          v-else-if="this.chargerChoice == '70W'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/apple/chargers/70W_charger_side.webp"
            alt="Apple Charger 70W"
            :class="{ available_colors_image_active: pictureChoice == 'side' }"
            @click="changePicture('side')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/apple/chargers/70W_charger_front.webp"
            alt="Apple Charger 70W"
            :class="{ available_colors_image_active: pictureChoice == 'front' }"
            @click="changePicture('front')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/apple/chargers/70W_charger_back.webp"
            alt="Apple Charger 70W"
            :class="{
              available_colors_image_active: pictureChoice == 'back',
            }"
            @click="changePicture('back')"
          />
        </div>
        <div
          class="available_colors"
          name="A_lightning_colors"
          v-else-if="this.chargerChoice == '96W'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/apple/chargers/96W_charger_side.webp"
            alt="Apple Charger 96W"
            :class="{ available_colors_image_active: pictureChoice == 'side' }"
            @click="changePicture('side')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/apple/chargers/96W_charger_front.webp"
            alt="Apple Charger 96W"
            :class="{ available_colors_image_active: pictureChoice == 'front' }"
            @click="changePicture('front')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/apple/chargers/96W_charger_back.webp"
            alt="Apple Charger 96W"
            :class="{
              available_colors_image_active: pictureChoice == 'back',
            }"
            @click="changePicture('back')"
          />
        </div>
        <div
          class="available_colors"
          name="A_lightning_colors"
          v-else-if="this.chargerChoice == '140W'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/apple/chargers/140W_charger_side.webp"
            alt="Apple Charger 140W"
            :class="{ available_colors_image_active: pictureChoice == 'side' }"
            @click="changePicture('side')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/apple/chargers/140W_charger_front.webp"
            alt="Apple Charger 140W"
            :class="{ available_colors_image_active: pictureChoice == 'front' }"
            @click="changePicture('front')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/apple/chargers/140W_charger_back.webp"
            alt="Apple Charger 140W"
            :class="{
              available_colors_image_active: pictureChoice == 'back',
            }"
            @click="changePicture('back')"
          />
        </div>
      </div>
    </div>
    <div name="personal_opinion" class="personal_opinion">
      <h3>Personal opinion</h3>
      <p>
        Personally, I would always go for an original Apple charger instead of a
        fake one. I've seen plenty of fake chargers in my life to know that most
        of them are made with cheap materials. If we look at the picture below,
        we can see the difference between the counterfeit charger (left) and the
        original charger (right). Since the charger on the right has more
        components, it makes the charger safer. The counterfeit charger also is
        more likely to catch on fire.
      </p>
      <figure class="single_picture">
        <img class="single_image" src="../../../assets/apple/chargers/charger_comparison.webp" alt="" />
        <figcaption>
          &copy;
          <a
            class="link_to_external_page"
            href="http://www.righto.com/2016/03/counterfeit-macbook-charger-teardown.html"
            >Righto.com</a
          >, Counterfeit Macbook charger teardown
        </figcaption>
      </figure>
      <p>
        Want to make sure you're buying the right charger for your MacBook? Take
        another look at the recommendations I've given above and choose the
        right one. Happy charging!
      </p>
    </div>

    <div name="CTA_div" class="CTA_div">
      <a
        v-if="this.chargerChoice == '30W'"
        class="button"
        :href="$store.state.link_to_30W_charger"
        >Shop Now</a
      ><a
        v-else-if="this.chargerChoice == '70W'"
        class="button"
        :href="$store.state.link_to_70W_charger"
        >Shop Now</a
      ><a
        v-else-if="this.chargerChoice == '96W'"
        class="button"
        :href="$store.state.link_to_96W_charger"
        >Shop Now</a
      ><a
        v-else-if="this.chargerChoice == '140W'"
        class="button"
        :href="$store.state.link_to_140W_charger"
        >Shop Now</a
      >
    </div>
  </div>
  <div name="similar_products" class="similar_products">
    <h3>Similar products</h3>
    <h4>
      <a class="link_to_internal_page" href="/brands/apple/iphone_and_ipad_chargers"
        >iPhone and iPad charger</a
      >
    </h4>
    <p>
      The chargers we've seen in this page are mostly recommended for MacBooks.
      Although the iPhone and iPad also use USB-C for charging nowadays, they
      have a different recommended charger. Read here more about the recommended
      charger for
      <a class="link_to_internal_page" href="/brands/apple/iphone_and_ipad_chargers"
        >iPhone and iPad </a
      >from Apple.
    </p>
  </div>
</template>
  
  <script>
export default {
  data() {
    return { chargerChoice: "30W", pictureChoice: "side" };
  },
  methods: {
    changePage(string) {
      this.chargerChoice = string;
      this.pictureChoice = "side";
    },
    changePicture(string) {
      this.pictureChoice = string;
    },
  },
};
</script>