<template>
  <nav>
    <router-link class="router-link" to="/">Home</router-link>
    <div class="dropdown">
      <router-link class="router-link dropbtn" to="/brands/apple">Apple</router-link>
      <div class="dropdown-content">
        <router-link class="router-link" to="/brands/apple#accessories">Accessories</router-link>
        <router-link class="router-link" to="/brands/apple#chargers">Chargers</router-link>
      </div>
    </div>
    <router-link class="router-link dropbtn" to="/brands/belkin">Belkin</router-link>
    <div class="dropdown">
      <router-link class="router-link dropbtn" to="/brands/native_union">Native Union
      </router-link>
      <div class="dropdown-content">
        <router-link class="router-link" to="/brands/native_union#cables">Cables</router-link>
        <router-link class="router-link" to="/brands/native_union#wireless_chargers">Wireless chargers</router-link>
      </div>
    </div>
    <router-link class="router-link dropbtn" to="/types_of_products">Types of products</router-link>
    <router-link class="router-link dropbtn" to="/functionalities_explained">Functionalities Explained</router-link>
  </nav>
</template>

<style scoped>
nav {
  overflow: hidden;
}

.router-link {
  float: left;
  text-align: center;
  text-decoration: none;
}

nav a {
  float: left;
  font-size: 16px;
  color: #fafafc;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: #fafafc;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

nav a:hover,
.dropdown:hover .dropbtn {
  background-color: #2f3047;
  border-radius: 5px;
}

.dropdown-content {
  margin-top: 48px;
  display: none;
  position: absolute;
  background-color: #28293d;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: #fafafc;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #2f3047;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>

<script></script>