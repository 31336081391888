<template>
  <div name="title">
    <h2>Introducing the Native Union Snap 3-in-1 Magnetic Wireless Charger</h2>
  </div>
  <div name="content">
    <p>
      Tired of dealing with multiple charging cables cluttering your space?
      Simplify your charging routine with the Native Union Snap 3-in-1 Magnetic
      Wireless Charger. This innovative charging solution offers seamless
      compatibility with your devices, ensuring effortless charging whenever you
      need it.
    </p>
    <h3>Are you tired of...</h3>
    <ul>
      <li>Juggling multiple charging cables for your various devices?</li>
      <li>
        Dealing with messy cables that clutter your workspace or bedside table?
      </li>
      <li>
        Struggling to find a reliable wireless charger that works with all your
        devices?
      </li>
    </ul>
    <p>
      Say goodbye to these frustrations with the Native Union Snap 3-in-1
      Magnetic Wireless Charger. With its versatile design and advanced
      technology, this charger provides a convenient and efficient charging
      experience for your smartphone, smartwatch, and earbuds.
    </p>
    <div class="images_and_colors">
      <div name="content_images">
        <div class="head_image_div">
          <img v-if="this.pictureChoice == 'head'" class="head_image"
            src="../../../assets/native_union/magnetic_chargers/3_in_1_magnetic_charger.webp"
            alt="Belt Cable Cosmos" /><img v-else-if="this.pictureChoice == 'front'" class="head_image"
            src="../../../assets/native_union/magnetic_chargers/3_in_1_magnetic_charger_front.webp"
            alt="Belt Cable Cosmos" /><img v-else-if="this.pictureChoice == 'charging'" class="head_image"
            src="../../../assets/native_union/magnetic_chargers/3_in_1_magnetic_charger_charging.webp"
            alt="Belt Cable Cosmos" />
        </div>
      </div>
      <div name="available_colors" class="available_colors_parent">
        <h3 class="available_colors_text">Other images:</h3>
        <div class="available_colors" name="A_lightning_colors">
          <img class="available_colors_image"
            src="../../../assets/native_union/magnetic_chargers/3_in_1_magnetic_charger.webp"
            alt="3 in 1 Magnetic Charger" :class="{ available_colors_image_active: pictureChoice == 'head' }"
            @click="changePicture('head')" />
          <img class="available_colors_image"
            src="../../../assets/native_union/magnetic_chargers/3_in_1_magnetic_charger_front.webp"
            alt="3 in 1 Magnetic Charger" :class="{
              available_colors_image_active: pictureChoice == 'front',
            }" @click="changePicture('front')" />
          <img class="available_colors_image"
            src="../../../assets/native_union/magnetic_chargers/3_in_1_magnetic_charger_charging.webp"
            alt="3 in 1 Magnetic Charger" :class="{
              available_colors_image_active: pictureChoice == 'charging',
            }" @click="changePicture('charging')" />
        </div>
      </div>
    </div>
    <div name="features" class="product_features">
      <h3>Product features</h3>
      <ol>
        <li>
          <b>3-in-1 Charging: </b>Charge your smartphone, smartwatch, and
          earbuds simultaneously with the Snap 3-in-1 Magnetic Wireless Charger.
          Whether you're at home, in the office, or on the go, this charger
          offers a streamlined solution for all your charging needs.
        </li>
        <li>
          <b>Magnetic Alignment: </b>The built-in magnets ensure perfect
          alignment for your devices, allowing for optimal charging efficiency
          every time. Say goodbye to misaligned devices and unreliable charging.
        </li>
        <li>
          <b>Fast Wireless Charging: </b>Equipped with advanced Qi
          <a class="link_to_internal_page" href="/types_of_products/wireless_chargers">
            wireless charging technology</a>, the Snap Charger delivers fast and efficient charging for your
          devices. Say goodbye to slow charging times and hello to quick
          power-ups.
        </li>
        <li>
          <b>Sleek Design: </b>With its sleek and minimalist design, the Snap
          Charger complements any environment, whether it's your home or office.
          Say goodbye to bulky chargers that detract from your space's
          aesthetics.
        </li>
      </ol>
    </div>
    <p>
      Upgrade your charging setup and simplify your life with the Native Union
      Snap
      <a class="link_to_external_page" :href="$store.state.link_to_3_in_1_magnetic_charger">
        3-in-1 Magnetic Wireless Charger</a>.
    </p>
    <div name="personal_opinion" class="personal_opinion">
      <h3>Personal opinion</h3>
      <p>
        The 3-in-1 Magnetic Wireless Charger is one of my personal favorites.
        When coming home after a long day I can just walk up to this charging
        station, drop my iPhone, my Apple Watch and my
        <a class="link_to_internal_page" href="/brands/apple/airpods_pro">AirPods Pro</a>
        all on the same charger. Since it functions as a
        <a class="link_to_internal_page" href="/types_of_products/fast_chargers">fast charger</a>,
        all my devices are charged for whenever I need to leave home soon again.
      </p>
    </div>

    <div name="CTA_div" class="CTA_div">
      <a class="button" :href="$store.state.link_to_3_in_1_magnetic_charger">Shop Now</a>
    </div>
  </div>
  <div name="similar_products" class="similar_products">
    <h3>Similar products</h3>
    <h4>
      <a class="link_to_internal_page" href="/brands/native_union/2_in_1_magnetic_charger">2-in-1 Magnetic Charger</a>
    </h4>
    <p>
      While the 3-in-1 Magnetic Charger charging options has for a phone,
      earbuds and an Apple Watch, not everyone tends to have (or want) an Apple
      Watch. For these people there is another version, namely the 2-in-1
      Magnetic Charger. This charger is built with the same ever-lasting
      materials, but lacks the Apple Watch charger. Read here more about Native
      Union's
      <a class="link_to_internal_page" href="/brands/native_union/2_in_1_magnetic_charger">2-in-1 Magnetic Charger</a>
    </p>

    <h4>
      <a class="link_to_internal_page" href="/brands/native_union/drop_charger">Drop Charger</a>
    </h4>
    <p>
      Feel like only charging one device at a time? Then the Drop Charger might
      be the solution for you! With a built in LED indicating that your device
      is charging, you can always have your phone or earbuds ready when you need
      them. Read here more about Native Union's
      <a class="link_to_internal_page" href="/brands/native_union/drop_charger">Drop Charger</a>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return { pictureChoice: "head" };
  },
  methods: {
    changePicture(string) {
      this.pictureChoice = string;
    },
  },
};
</script>