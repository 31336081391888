<template>
  <div name="title">
    <h2>
      Discover the New Apple AirPods Pro 2 - Premium Sound, Enhanced Comfort
    </h2>
  </div>
  <div name="content">
    <p>
      Elevate your audio experience with the new Apple AirPods Pro 2. Designed
      for superior sound quality and all-day comfort, these wireless earbuds are
      the perfect companion for your active lifestyle. Immerse yourself in rich,
      immersive sound while enjoying advanced features like active noise
      cancellation and a customizable fit. Get ready to upgrade your listening
      game with Apple's latest innovation in wireless earbuds.
    </p>
    <p>
      Say goodbye to tangled wires and uncomfortable earbuds with the Apple
      AirPods Pro 2. These cutting-edge earbuds provide a seamless wireless
      experience, eliminating the hassle of cords. Enjoy crystal-clear audio and
      block out distractions with active noise cancellation, perfect for travel
      or focus. The customizable fit ensures a snug and comfortable feel, ideal
      for extended wear without discomfort. Get ready to enjoy music, podcasts,
      and calls with unparalleled clarity and convenience.
    </p>
    <div class="images_and_colors">
      <div name="content_images">
        <div class="head_image_div">
          <img v-if="this.pictureChoice == 'main'" class="head_image" src="../../../assets/apple/airpods_pro/airpods_pro_main.webp" alt="AirPods Pro">
          <img v-if="this.pictureChoice == 'out'" class="head_image" src="../../../assets/apple/airpods_pro/airpods_pro_out.webp" alt="AirPods Pro">
          <img v-if="this.pictureChoice == 'in'" class="head_image" src="../../../assets/apple/airpods_pro/airpods_pro_in.webp" alt="AirPods Pro">
          <img v-if="this.pictureChoice == 'side'" class="head_image" src="../../../assets/apple/airpods_pro/airpods_pro_side.webp" alt="AirPods Pro">
          <img v-if="this.pictureChoice == 'bottom'" class="head_image" src="../../../assets/apple/airpods_pro/airpods_pro_bottom.webp" alt="AirPods Pro">
          <img v-if="this.pictureChoice == 'charger'" class="head_image" src="../../../assets/apple/airpods_pro/airpods_pro_charger.webp" alt="AirPods Pro">
        </div>
      </div>
      <div name="available_colors" class="available_colors_parent">
        <h3 class="available_colors_text">Available pictures:</h3>
        <div class="available_colors">
          <img class="available_colors_image" src="../../../assets/apple/airpods_pro/airpods_pro_main.webp" alt="AirPods Pro" :class="{available_colors_image_active: pictureChoice == 'main'}" @click="changePicture('main')">
          <img class="available_colors_image" src="../../../assets/apple/airpods_pro/airpods_pro_out.webp" alt="AirPods Pro" :class="{available_colors_image_active: pictureChoice == 'out'}" @click="changePicture('out')">
          <img class="available_colors_image" src="../../../assets/apple/airpods_pro/airpods_pro_in.webp" alt="AirPods Pro" :class="{available_colors_image_active: pictureChoice == 'in'}" @click="changePicture('in')">
          <img class="available_colors_image" src="../../../assets/apple/airpods_pro/airpods_pro_side.webp" alt="AirPods Pro" :class="{available_colors_image_active: pictureChoice == 'side'}" @click="changePicture('side')">
          <img class="available_colors_image" src="../../../assets/apple/airpods_pro/airpods_pro_bottom.webp" alt="AirPods Pro" :class="{available_colors_image_active: pictureChoice == 'bottom'}" @click="changePicture('bottom')">
          <img class="available_colors_image" src="../../../assets/apple/airpods_pro/airpods_pro_charger.webp" alt="AirPods Pro" :class="{available_colors_image_active: pictureChoice == 'charger'}" @click="changePicture('charger')">
        </div>
      </div>
    </div>
    <p></p>
    <div name="features" class="product_features">
      <h3>Product features</h3>
      <ol>
        <li>
          <b> Active Noise Cancellation:</b> Immerse yourself in your music
          while blocking out external noise for a focused listening experience.
        </li>
        <li>
          <b>Customizable Fit:</b> Choose from three sizes of silicone ear tips
          for a secure and comfortable fit that stays in place.
        </li>
        <li>
          <b>Transparency Mode:</b> Stay aware of your surroundings with
          Transparency Mode, allowing natural sound to come through when needed.
        </li>
        <li>
          <b>Spatial Audio:</b> Experience immersive, theater-like sound with
          dynamic head tracking for a cinematic listening experience.
        </li>
        <li>
          <b>Sweat and Water Resistance:</b> Designed to withstand workouts and
          outdoor activities with IPX4-rated sweat and water resistance.
        </li>
      </ol>
      <p>
        The Apple AirPods Pro 2 redefine wireless earbuds with premium sound
        quality, active noise cancellation, and a customizable fit. Whether
        you're commuting, working out, or relaxing at home, these earbuds
        deliver exceptional audio performance and comfort. Stay connected,
        immerse yourself in your favorite music, and enjoy the convenience of
        wireless freedom with the AirPods Pro 2. Upgrade your audio experience
        today.
      </p>
    </div>
    <div name="personal_opinion" class="personal_opinion">
      <h3>Personal opinion</h3>
      <p>In the past I've had multiple sets of different earbuds, mostly wired. However, once I switched to wireless with the purchase of these AirPods Pro, I never wanted to go back. Having the freedom to move away from your device without picking it up was a life changer. Combine that with the crystal clear audio, handsfree Siri commands (with "Hey Siri") and automatic device switching makes the AirPods Pro my all time favorite headphones. Because the AirPods Pro are stored (and charged) in a small case, I can easily take it with me anywhere I go and they're always ready to play me my favorite shows and music.</p>
      <p>Because the AirPods Pro come with different sizes of earbuds, they should fit in every ear. Since the tip is made of silicon, they won't hurt as much as no tips would. However, I don't really like the silicon eartips, so I bought a pair of memory foam tips which I've had for over 3 years now and they're still amazing.</p>
      <p>All in all, I love the AirPods Pro and their amazing sound and connectivity and I would definitely recommend these as a daily gadget to your life.</p>
    </div>

    <div name="CTA_div" class="CTA_div">
      <a class="button" :href="$store.state.link_to_airpods_pro">Shop Now</a>
    </div>
  </div>
  <!-- <div name="similar_products" class="similar_products">
    <h3>Similar products</h3>
    <h4><a href=""></a></h4>
    <p><a href=""></a></p>
  </div> -->
</template>

<script>
export default {
  data() {
    return { pictureChoice: "main" };
  },
  methods: {
    changePicture(string) {
      this.pictureChoice = string;
    },
  },
};
</script>