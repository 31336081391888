<template>
  <div name="title">
    <h2 v-if="this.cableChoice == 'USB_C_Lightning'">
      Introducing Belkin's BoostCharge USB-C to Lightning Cable
    </h2>
    <h2 v-else-if="this.cableChoice == 'USB_A_Lightning'">
      Introducing Belkin's BoostCharge USB-A to Lightning Cable
    </h2>
  </div>
  <div name="changeProductButtons" class="changeProductOnPageDiv">
    <button
      :class="{ active: cableChoice == 'USB_C_Lightning' }"
      class="changeProductOnPageButton"
      @click="changePage('USB_C_Lightning')"
    >
      USB-C to Lightning
    </button>
    <button
      :class="{ active: cableChoice == 'USB_A_Lightning' }"
      class="changeProductOnPageButton"
      @click="changePage('USB_A_Lightning')"
    >
      USB-A to Lightning
    </button>
  </div>
  <div name="content">
    <p>
      Tired of slow charging speeds and unreliable cables that fray easily?
      Upgrade your charging experience with Belkin's BoostCharge cables. These
      premium cables are designed to address common charging frustrations and
      deliver fast, efficient charging for your devices.
    </p>
    <h3>Are you tired off...</h3>
    <ul>
      <li>
        Waiting ages for your device to charge due to slow charging speeds?
      </li>
      <li>Dealing with flimsy cables that break or fray after minimal use?</li>
      <li>
        Struggling to find a reliable cable that matches your fast-paced
        lifestyle?
      </li>
    </ul>
    <p>
      Say goodbye to these frustrations with Belkin's BoostCharge cables.
      Whether you prefer USB-C or USB-A connectivity, these cables offer a
      solution to your charging needs with enhanced durability and performance.
    </p>
    <div class="images_and_colors">
      <div name="content_images">
        <div
          v-if="this.cableChoice == 'USB_C_Lightning'"
          class="head_image_div"
        >
          <img
            v-if="this.pictureChoice == 'black'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_cable/boostcharge_cable_USB_C_black.webp"
            alt="Belkin BoostCharge Cable Black"
          />
          <img
            v-else-if="this.pictureChoice == 'white'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_cable/boostcharge_cable_USB_C_white.webp"
            alt="Belkin BoostCharge Cable White"
          />
        </div>
        <div
          v-else-if="this.cableChoice == 'USB_A_Lightning'"
          class="head_image_div"
        >
          <img
            v-if="this.pictureChoice == 'black'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_cable/boostcharge_cable_USB_A_black.webp"
            alt="Belt Cable Cosmos"
          />
          <img
            v-else-if="this.pictureChoice == 'white'"
            class="head_image"
            src="../../../assets/belkin/boostcharge_cable/boostcharge_cable_USB_A_white.webp"
            alt="Belkin BoostCharge Cable White"
          />
        </div>
      </div>
      <div name="available_colors" class="available_colors_parent">
        <h3 class="available_colors_text">Available colors:</h3>
        <div
          class="available_colors"
          name="A_lightning_colors"
          v-if="this.cableChoice == 'USB_C_Lightning'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_cable/boostcharge_cable_USB_C_black.webp"
            alt="Belkin BoostCharge Cable Black"
            :class="{ available_colors_image_active: pictureChoice == 'black' }"
            @click="changePicture('black')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_cable/boostcharge_cable_USB_C_white.webp"
            alt="Belkin BoostCharge Cable White"
            :class="{
              available_colors_image_active: pictureChoice == 'white',
            }"
            @click="changePicture('white')"
          />
        </div>
        <div
          class="available_colors"
          name="A_C_colors"
          v-else-if="this.cableChoice == 'USB_A_Lightning'"
        >
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_cable/boostcharge_cable_USB_A_black.webp"
            alt="Belkin BoostCharge Cable Black"
            :class="{ available_colors_image_active: pictureChoice == 'black' }"
            @click="changePicture('black')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/belkin/boostcharge_cable/boostcharge_cable_USB_A_white.webp"
            alt="Belkin BoostCharge Cable White"
            :class="{ available_colors_image_active: pictureChoice == 'white' }"
            @click="changePicture('white')"
          />
        </div>
      </div>
    </div>
    <p></p>
    <div name="features" class="product_features">
      <h3>Product features</h3>
      <ol>
        <li>
          <b>Fast Charging: </b>BoostCharge cables support fast charging for
          compatible devices, delivering optimal power for quick and efficient
          charging sessions.
        </li>
        <li>
          <b>MFi Certified: </b>Certified by Apple's MFi (Made for iPhone/iPad)
          program, these cables ensure seamless compatibility and safe charging
          for your iOS devices.
        </li>
        <li>
          <b>Durable Construction: </b>The cables are built with high-quality
          materials and reinforced connectors to withstand daily wear and tear,
          preventing fraying and ensuring long-lasting durability.
        </li>
        <li>
          <b>Versatile Connectivity: </b>Choose between USB-C to Lightning or
          USB-A to Lightning options based on your device and charging
          preferences. Enjoy universal compatibility with iPhones, iPads, and
          other Lightning-enabled devices.
        </li>
        <li>
          <b>Length Options: </b>Available in different length options to suit
          your needs, from short and portable cables ideal for travel to longer
          cables for convenient use at home or in the office.
        </li>
      </ol>
      <p>
        Upgrade your charging setup and say hello to reliable, fast charging
        with Belkin's BoostCharge
        <a
          v-if="this.cableChoice == 'USB_C_Lightning'"
          class="link_to_external_page"
          :href="$store.state.link_to_boostcharge_cable_USB_C"
        >
          USB-C to Lightning cable</a
        >
        <a
          v-else-if="this.cableChoice == 'USB_A_Lightning'"
          class="link_to_external_page"
          :href="$store.state.link_to_boostcharge_cable_USB_A"
        >
          USB-A to Lightning cable</a
        >
        .
      </p>
    </div>
    <div name="personal_opinion" class="personal_opinion">
      <h3>Personal opinion</h3>
      <p>
        The braided BoostCharge cable from Belkin is a good alternative to the
        <a class="link_to_internal_page" href="/brands/native_union#cables"
          >Native Union Cables</a
        >. It's durable and reliable, although the warranty is less than what
        you get if you buy a Native Union Cable. While Belkin's warranty is 2
        years, the Native Union warranty is a lifelong warrany. The Native Union
        cable is also about 1 feet (20 cm) longer. Personally, I would buy a
        Native Union version for these benefits and only 10 bucks more, however
        opting to go for the Belkin braided cable is absolutely not a bad
        choice.
      </p>
    </div>
    <div name="CTA_div" class="CTA_div">
      <a
        v-if="this.cableChoice == 'USB_C_Lightning'"
        class="button"
        :href="$store.state.link_to_boostcharge_cable_USB_C"
        >Shop Now</a
      >
      <a
        v-else-if="this.cableChoice == 'USB_A_Lightning'"
        class="button"
        :href="$store.state.link_to_boostcharge_cable_USB_A"
        >Shop Now</a
      >
    </div>
  </div>
  <div name="similar_products" class="similar_products">
    <h3>Similar products</h3>
    <h4>
      <a class="link_to_internal_page" href="/brands/native_union/belt_cable"
        >Native Union Belt Cable</a
      >
    </h4>
    <p>
      The
      <a class="link_to_internal_page" href="/brands/native_union/belt_cable"
        >Native Union Belt Cable</a
      >
      is also a braided and durable cable capable of fastcharging your devices.
      This product is available in more colors than the Belkin BoostCharge cable
      and has a longer warranty, however it is a bit more expensive. Read more
      about the
      <a class="link_to_internal_page" href="/brands/native_union/belt_cable"
        >Native Union Belt Cable</a
      >.
    </p>
  </div>
</template>
  
  <script>
export default {
  data() {
    return { cableChoice: "USB_C_Lightning", pictureChoice: "black" };
  },
  methods: {
    changePage(string) {
      this.cableChoice = string;
      this.pictureChoice = "black";
    },
    changePicture(string) {
      this.pictureChoice = string;
    },
  },
};
</script>