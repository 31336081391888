<template>
  <div name="title">
    <h2>Introducing the 20W Apple USB-C Charger for iPhone and iPad</h2>
  </div>
  <div name="content">
    <p>
      Say hello to the future of charging with the 20W Apple USB-C Charger.
      Designed to deliver fast and efficient charging for your iPhone, iPad, and
      other compatible devices, this sleek charger represents a significant
      advancement in charging technology.
    </p>
    <h3>Embrace the Change:</h3>
    <p>
      Gone are the days of relying on the outdated USB-A charger. The 20W Apple
      USB-C Charger has taken over, bringing with it faster charging speeds and
      a more streamlined charging experience. With its USB-C port, this charger
      is ready to power up your devices at lightning speed.
    </p>
    <h3>Every iPhone and iPad Deserves the Best:</h3>
    <p>
      Gone are the days of traditional packaging. With Apple's commitment to
      sustainability and innovation, your new iPhone or iPad now arrives with
      just the essential USB-C cable included, sans the charger. This
      eco-friendly approach not only reduces electronic waste but also
      contributes to significant environmental benefits, such as saving space in
      transportation. By opting for the 20W Apple USB-C Charger, you're not only
      upgrading your charging experience but also supporting Apple's efforts
      towards a greener future.
    </p>

    <div class="images_and_colors">
      <div name="content_images">
        <div class="head_image_div">
          <img
            v-if="this.pictureChoice == 'side'"
            class="head_image"
            src="../../../assets/apple/chargers/20W_charger_side.webp"
            alt="20W Charger Side"
          />
          <img
            v-else-if="this.pictureChoice == 'front'"
            class="head_image"
            src="../../../assets/apple/chargers/20W_charger_front.webp"
            alt="20W Charger Front"
          />
          <img
            v-else-if="this.pictureChoice == 'back'"
            class="head_image"
            src="../../../assets/apple/chargers/20W_charger_back.webp"
            alt="20W Charger Back"
          />
        </div>
      </div>
      <div name="available_colors" class="available_colors_parent">
        <h3 class="available_colors_text">Available images:</h3>
        <div class="available_colors" name="A_lightning_colors">
          <img
            class="available_colors_image"
            src="../../../assets/apple/chargers/20W_charger_side.webp"
            alt="20W Charger Side"
            :class="{
              available_colors_image_active: pictureChoice == 'side',
            }"
            @click="changePicture('side')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/apple/chargers/20W_charger_front.webp"
            alt="20W Charger Front"
            :class="{
              available_colors_image_active: pictureChoice == 'front',
            }"
            @click="changePicture('front')"
          />
          <img
            class="available_colors_image"
            src="../../../assets/apple/chargers/20W_charger_back.webp"
            alt="20W Charger Back"
            :class="{
              available_colors_image_active: pictureChoice == 'back',
            }"
            @click="changePicture('back')"
          />
        </div>
      </div>
    </div>
    <p></p>
    <div name="features" class="product_features">
      <h3>Product features</h3>
      <ol>
        <li>
          <b>20W Fast Charging: </b>Experience lightning-fast charging speeds
          with the 20W Apple USB-C Charger. Whether you're topping up your
          iPhone or powering up your iPad, this charger delivers the power you
          need in record time.
        </li>
        <li>
          <b>Compact and Portable: </b>With its compact design, the 20W Apple
          USB-C Charger is perfect for on-the-go charging. Slip it into your bag
          or pocket and stay powered up wherever you are.
        </li>
        <li>
          <b>Versatile Compatibility: </b>This charger is not just for iPhones
          and iPads. With its USB-C port, it's compatible with a wide range of
          devices, including smartphones, tablets, and even laptops. Charge your
          devices with ease and confidence, knowing that you're getting the best
          possible charging experience.
        </li>
        <li>
          <b>Apple Quality Assurance: </b>As with all Apple products, the 20W
          USB-C Charger undergoes rigorous testing to ensure reliability and
          safety. Charge your devices with peace of mind, knowing that you're
          using a charger that meets Apple's high standards.
        </li>
        <li>
          <b>Advanced Charging Technology: </b>The 20W Apple USB-C Charger is
          equipped with advanced charging technology that automatically detects
          and delivers the optimal charging power for your device. Say goodbye
          to overcharging and overheating, and hello to efficient and safe
          charging.
        </li>
      </ol>
      <p>
        Upgrade your charging setup and embrace the future of charging with the
        <a
          class="link_to_external_page"
          :href="$store.state.link_to_20W_charger"
        >
          20W Apple USB-C Charger</a
        >.
      </p>
    </div>
    <div name="personal_opinion" class="personal_opinion">
      <h3>Personal opinion</h3>
      <p>
        While previously the provided charging cables were all type USB-A, Apple
        switched the cables to USB-C connectors since 2022. This means that the
        USB-A cables (and chargers) are slowly fading out and its leaving a gap,
        which USB-C neatly fills. This also means that most chargers you use
        with the iPhone, now are
        <a class="link_to_internal_page" href="/types_of_products/fast_chargers">fast chargers</a>. Personally, I prefer the
        Apple (original) fast chargers over any other type of charger. It
        charges the phone faster than the older Apple chargers and I personally
        find it more reliable since I know it's been through a lot of quality
        checks.
      </p>
    </div>

    <div name="CTA_div" class="CTA_div">
      <a class="button" :href="$store.state.link_to_20W_charger">Shop Now</a>
    </div>
  </div>
  <div name="similar_products" class="similar_products">
    <h3>Similar products</h3>
    <h4><a class="link_to_internal_page" href="/brands/belkin/boostcharge_adapter">BoostCharge adapter</a></h4>
    <p>The <a class="link_to_internal_page" href="/brands/belkin/boostcharge_adapter">BoostCharge adapter</a> from Belkin is a more affordable option than Apple's 20W charger and comes has the option to charge multiple devices at the same time, also having the option to use a USB-A charging cable.</p>
  </div>
</template>
  
  <script>
export default {
  data() {
    return { pictureChoice: "side" };
  },
  methods: {
    changePicture(string) {
      this.pictureChoice = string;
    },
  },
};
</script>